import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import ROUTES from '../../../../../utils/routes'
import { Button, Layout, Tabs, Typography } from 'antd'
import ProfileCompanyDetails from './components/company-details'
import ProfileCustomization from './components/customization'
import ProfileSpecializations from './components/specializations'
import ProfileTestimonials from './components/testimonials'
import ProfileFaq from './components/faq'
import ProfileMembership from './components/membership'
import ProfileTechTeam from './components/tech-team'
import './assets/styles.scss'
import PageFallback from '../../../../components/page-fallback'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, getAndSetGroupCoversation } from '../../../../../utils/helper'
import useWindowSize from '../../../../../hooks/useWindowSize'
import ExternalIntegration from './components/external-integrations'

const { Content } = Layout
const { Title, Text } = Typography

const ItcProfile = () => {
    const tech = useSelector(state => state.tech)
    const [itcDetails, setItcDetails] = useState({})
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [locationDetails, setLocationDetails] = useState({})
    const [loadingLocationDetails, setLoadingLocationDetails] = useState(false)
    const [groupConversation, setGroupConversation] = useState({})
    const history = useHistory()
    const {windowWidth} = useWindowSize();
    
    const param = useParams()

    const getItcDetails = () => {
        setLoadingDetails(true)
        ApiBaseHelper.get({
            url: `itc/${param?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcDetails(res?.data?.data)
            setLoadingDetails(false)
            if(tech.id !== res?.data?.data?.Owner_Contact){
                history?.push(ROUTES.ITC_HOME.replace(':subdomain', res?.data?.data?.Subdomain))
                return
            }
        }).catch((error) => {
            console.error(error)
            setLoadingDetails(false)
        })       
    }

    const getLocationDetails = () => {
        setLoadingLocationDetails(true)
        ApiBaseHelper.get({
            url: `home/${itcDetails?.Location}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setLocationDetails(res?.data?.data)
            setLoadingLocationDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingLocationDetails(false)
        })       
    }

    useEffect(() => {
        getItcDetails()
    }, [])

    useEffect(() => {
        if(itcDetails){
            getLocationDetails()
            // getAndSetGroupCoversation(itcDetails?.Company_Name).then((res) => {
            //     setGroupConversation(res)
            // })
            
        }
    }, [itcDetails])

    const tabListInit = [
        {
            title:"Company Details",
            content: <ProfileCompanyDetails
                itcDetails={itcDetails}
                locationDetails={locationDetails}
             />
        },
        {
            title:"Customization",
            content: <ProfileCustomization 
                itcDetails={itcDetails}
                itcId = {param?.id}
                token = {tech?.token}
            />
        },
        {
            title:"External Integrations",
            content: <ExternalIntegration
                itcDetails={itcDetails}
                itcId = {param?.id}
                token = {tech?.token}
            />
        },
        {
            title:"Specializations",
            content: <ProfileSpecializations 
                itcId = {param?.id}
                token = {tech?.token}
            />
        },
        {
            title:"Testimonials",
            content: <ProfileTestimonials 
                itcId = {param?.id}
                token = {tech?.token}
            />
        },
        {
            title:"FAQs",
            content: <ProfileFaq 
                itcId = {param?.id}
                token = {tech?.token}
            />
        },
        {
            title:"Membership Fee",
            content: <ProfileMembership 
                itcDetails={itcDetails}
                itcId = {param?.id}
                token = {tech?.token}
            />
        },
        {
            title:"Tech Team",
            content: <ProfileTechTeam 
                itcDetails={itcDetails}
                itcId = {param?.id}
            />
        },    
    ]

    let tabList = itcDetails?.isProject === "1" ? tabListInit.filter((item) => item.title.toLowerCase() !== "testimonials") : tabListInit

    if(loadingDetails || loadingLocationDetails) {
        return <PageFallback />
    }

    return (
        <>
            <MetaTags>
                <title>IT Services Company Profile - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.ITC_PROFILES,  
                        breadcrumbName: 'ITSC Profiles',
                    },
                    {
                        path: ROUTES.ITC_PROFILE,
                        breadcrumbName: capitalizeFirstLetter(itcDetails?.Company_Name) || 'ITSC Profile',
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn itc-profile-ctn">
                            {/* <div className="group-chat-error-ctn">
                                <img src="" alt="" />
                                <Title level={2} className="group-chat-error-title">Group Chat Error</Title>
                            </div> */}
                           <Tabs 
                                className='itc-profile-tabs'
                                tabBarExtraContent={ windowWidth > 768 &&
                                    <Button type='primary' onClick={() => history?.push(ROUTES.ITC_HOME.replace(':subdomain', itcDetails?.Subdomain))}>View Webpage</Button>
                                }
                            >
                                {tabList.map((item) => {
                                    return (
                                        <Tabs.TabPane tab={item.title} key={item.title}>
                                            <div className='itc-profile-content'>
                                                {item.content}
                                            </div>
                                        </Tabs.TabPane>
                                    )
                                })}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </Content>
        </>
  )
}

export default ItcProfile