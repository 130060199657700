// External Imports
import React, {Component, useEffect, Suspense, useState} from "react";
import {connect, useSelector} from 'react-redux'
import {Button, Col, Form, Image, Input, Modal, notification, Row, Typography} from 'antd';
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom"
import { useLocation } from 'react-router';
import moment from 'moment'
import TagManager from 'react-gtm-module';
import {isSupported} from "firebase/messaging";

// Internal Imports
import SignIn from './views/pages/common/public/authentication/login/index';
import SignUp from'./views/pages/common/public/authentication/sign-up/index';
import {ApiBaseHelper} from "./utils/api-base-helper";
import {updateFcmToken, updateInAppNotifications, updateTech, updateUser} from "./redux/reducers/app";
import ROUTES from './utils/routes';
import {FirebaseHelper} from "./utils/firebase-helper";
import {DEFAULT_PROFILE_PICTURE, GTM_ID} from "./utils/constants";
import PageFallback from "./views/components/page-fallback";
import SetPassword from './views/pages/common/public/authentication/set-password';

// Protected pre loaded routes
import HomePage from './views/pages/common/private/home/index';
import RealTimeMessaging from './views/pages/common/private/real-time-messaging/index';
import ManageProfile from "./views/pages/tech/manage/profile";
import MyService from "./views/pages/tech/manage/services/listing";
import ManageSkills from "./views/pages/tech/manage/skills/index";
import ManageSchedule from "./views/pages/tech/manage/schedule/index";
import Certification from "./views/pages/tech/manage/certifications";
import AccountSettings from "./views/pages/common/private/account-settings";
import DiscoveryExperience from "./views/pages/common/public/discovery-experience";
import Devices from "./views/pages/customer/manage/devices";
import Location from './views/pages/customer/manage/locations/index';
import InvoiceListing from './views/pages/common/private/invoice-listing/index';
import Payment from './views/pages/customer/manage/payment-methods/index';
import BookingDetails from "./views/pages/common/private/booking-details/index";
import ManageBooking from './views/pages/common/private/manage-bookings/index';
import Contracts from './views/pages/common/private/contracts/contract-listing';
import ViewEditContract from './views/pages/common/private/contracts/contract-details';
import TicketDetails from "./views/pages/common/private/ticket-details";
import TicketListing from "./views/pages/customer/ticket-listing";
import SavedProfilesList from "./views/pages/customer/saved-profiles-list";

import { STATES } from "./utils/us-states-city";
import SOLUTIONS from "./utils/solutions-page-data";
import INDUSTRIES from "./utils/industry-page-data"

// Assets Imports
import './assets/css/App.css';
import './assets/css/bootstrap.css';
import './assets/css/responsive.css';
import './views/pages/common/private/video-conferencing/css/calling.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-calendar/dist/Calendar.css';
import Logo from './assets/images/logo2.png'
import NewsLetterImage from './assets/images/frame.png'
import MessageIcon from './assets/images/message.png'
import DefaultPage from "./views/pages/common/public/default-page";
import SavedProfileDetails from "./views/pages/customer/saved-profiles-details";
import CreateSpecialization from "./views/pages/tech/manage/skills/components/create-specialization";
import UpdateSpecialization from "./views/pages/tech/manage/skills/components/update-specialization";
import UtilityHeader from "./views/components/utility-header";
import ResetPassword from "./views/pages/common/public/authentication/reset-password";
import TeamManagement from "./views/pages/customer/team-management";
import ClientOnboarding from "./views/pages/customer/client-onboarding";
import GuestSignUp from "./views/pages/common/public/authentication/guest-sign-up";
import TeamLogin from "./views/pages/common/public/authentication/team-login";
import AcceptInvite from "./views/pages/common/public/authentication/accept-invite";
import UserDashboard from "./views/pages/common/private/dashboard";
import JobPostings from "./views/pages/customer/job-postings";
import JobApplications from "./views/pages/customer/job-postings/applications";
import AddJobPosting from "./views/pages/customer/add-job-posting";
import ManageJobPosting from "./views/pages/customer/jobs/manage-job-post";
import JobPost from "./views/pages/common/private/job-post";
import SubmitJobApplication from "./views/pages/tech/jobs/submit-application";
import ManageJobApplications from "./views/pages/tech/jobs/manage-applications";
import JobApplicationDetails from "./views/pages/tech/jobs/manage-applications/application-details";
import JobBrowsing from "./views/pages/tech/jobs/job-browsing";
import ViewApplication from "./views/pages/customer/jobs/view-application";
import EditJobPost from "./views/pages/customer/jobs/edit-job-posting";
import Reports from "./views/pages/common/private/reports";
import ItcNav from "./views/pages/tech/itc/landing-page/components/nav";
import ItcFooter from "./views/pages/tech/itc/landing-page/components/footer";
import ItcLandingPage from "./views/pages/tech/itc/landing-page";
import CreateItc from "./views/pages/tech/itc/create-itc";
import ItcProfile from "./views/pages/tech/itc/itc-profile";
import ItcProfiles from "./views/pages/tech/itc/itc-profiles";
import InviteListings from "./views/pages/tech/itc/manage-invites/invite-listings";
import InviteDetails from "./views/pages/tech/itc/manage-invites/invite-details";
import DiscoverItc from "./views/pages/common/private/discover-itc";
import ItcDashboard from "./views/pages/tech/itc/itc-dashboard";
import ItcTechTeam from "./views/pages/tech/itc/itc-tech-team";


// Lazy Loading
const NewHome = React.lazy(() => import("./views/pages/common/public/landing-page"));
const AboutPage = React.lazy(() => import("./views/pages/common/public/new-about-page"));
const PrivacyPolicy = React.lazy(() => import("./views/pages/common/public/privacy-policy"));
const TermsAndConditions = React.lazy(() => import("./views/pages/common/public/terms-and-conditions"));
const ReportABug = React.lazy(() => import("./views/pages/common/public/report-a-bug"));
const Contact = React.lazy(() => import("./views/pages/common/public/contact-page"));
const PressPage = React.lazy(() => import("./views/pages/common/public/new-press-page"));
const PressListings = React.lazy(() => import("./views/pages/common/public/new-press-page/press-listings"));
const ItSolutionsPage = React.lazy(() => import("./views/pages/common/public/it-solutions"));
const AdminAddSkill = React.lazy(() => import("./views/pages/admin/skills/manage-skills"));
const AdminEditSkill = React.lazy(() => import("./views/pages/admin/skills/manage-skills"));
const AdminSkills = React.lazy(() => import("./views/pages/admin/skills"));
const AdminUserDetails = React.lazy(() => import("./views/pages/admin/user-details"));
const EmailVerification = React.lazy(() => import("./views/pages/common/private/email-verification"));
const ForgotPassword = React.lazy(() => import("./views/pages/common/public/authentication/forgot-password"));
const AddService = React.lazy(() => import("./views/pages/tech/manage/services/add"));
const EditService = React.lazy(() => import("./views/pages/tech/manage/services/edit/index"));
const OnBoarding = React.lazy(() => import("./views/pages/tech/on-boarding"));
const TechProfileNew = React.lazy(() => import("./views/pages/tech/tech-profile"));
const TechnicianService = React.lazy(() => import("./views/pages/tech/tech-service"));
const StripeConnect = React.lazy(() => import("./views/pages/tech/stripe-connect"));
const BookService = React.lazy(() => import("./views/pages/customer/book-service"));
const ExternalPage = React.lazy(() => import("./views/pages/common/public/general/iframe-page"));
const ProfileUpdate = React.lazy(() => import("./views/pages/common/private/edit-profile"));
const PageNoFound = React.lazy(() => import("./views/pages/common/public/general/page-not-found/index"));
const AddLocation = React.lazy(() => import("./views/pages/customer/manage/locations/add-location"));
const AddPayment = React.lazy(() => import('./views/pages/customer/manage/payment-methods/add-payment/index'));
const ServiceDetails = React.lazy(() => import("./views/pages/common/private/service-details"));
const NewInvoice = React.lazy(() => import('./views/pages/common/private/new-invoice'));
const AddEditDevice = React.lazy(() => import("./views/pages/customer/manage/devices/add-edit-device"));
const AddNewServiceCustomer = React.lazy(() => import("./views/pages/customer/add-new-service"));
const VideoConferencing = React.lazy(() => import('./views/pages/common/private/video-conferencing/index'));
const ChangePassword = React.lazy(() => import("./views/pages/common/private/change-password"));
const ReviewInvoice = React.lazy(() => import("./views/pages/tech/review-invoice"));
const CoMarketingPlan = React.lazy(() => import("./views/pages/common/public/general/co-marketing-plan"));
const AdminLogin = React.lazy(() => import("./views/pages/admin/login"));
const SideNavigation = React.lazy(() => import("./views/pages/admin/components/side-navigation"));
const AdminUsers = React.lazy(() => import("./views/pages/admin/users"));
const GroupConversations = React.lazy(() => import("./views/pages/admin/group-conversations"));
const ProtectedLayout = React.lazy(() => import("./views/components/layouts/protected-layout"));
const PromoModal = React.lazy(() => import('./views/components/modal'));
const AdminServices = React.lazy(() => import("./views/pages/admin/services"));
const AdminPromo = React.lazy(() => import("./views/pages/admin/promo"));
const AdminAddPromo = React.lazy(() => import("./views/pages/admin/promo/add/index"));
const AdminEditPromo = React.lazy(() => import("./views/pages/admin/promo/edit/index"));
const AdminTechApplication = React.lazy(() => import("./views/pages/admin/tech-application/index"));
const AdminEditTechApplication = React.lazy(() => import("./views/pages/admin/tech-application/edit/index"));
const AdminAddTechApplication = React.lazy(() => import("./views/pages/admin/tech-application/add/index"));
const AdminAddService = React.lazy(() => import("./views/pages/admin/services/add/index"));
const AdminEditService = React.lazy(() => import("./views/pages/admin/services/edit/index"));
const QuickBook = React.lazy(() => import("./views/pages/customer/book-service/quick-book"));
const BookSubscription = React.lazy(() => import("./views/pages/customer/book-service/book-subscription"));
const AdminManageBooking = React.lazy(() => import("./views/pages/admin/manage-booking/index"));
const AdminManageBookingView = React.lazy(() => import("./views/pages/admin/manage-booking/view/index"));
const AdminScheduleServices = React.lazy(() => import("./views/pages/admin/manage-schedule-booking/index"));
const AdminScheduleServicesView = React.lazy(() => import("./views/pages/admin/manage-schedule-booking/view/index"));
const AdminContracts = React.lazy(() => import("./views/pages/admin/contracts/contract-listing"));
const AdminContractDetails = React.lazy(() => import("./views/pages/admin/contracts/contract-details"));
const AdminTicketDetails = React.lazy(() => import("./views/pages/admin/contracts/ticket-details"));
const InstantSearch = React.lazy(() => import("./views/pages/common/public/instant-search"));
const CityPage = React.lazy(() => import("./views/pages/common/public/city-page"));
const StatePage = React.lazy(() => import("./views/pages/common/public/state-page"));
const SelectedCityPage = React.lazy(() => import("./views/pages/common/public/selected-city-page"));
const CreateSendContract = React.lazy(() => import("./views/pages/common/public/create-send-contract"));
const NewNavbar = React.lazy(() => import("./views/components/header-footer/navbar"));
const NewFooter = React.lazy(() => import("./views/components/header-footer/footer"));
const ItIndustry = React.lazy(() => import("./views/pages/common/public/it-industry"));

const { Title, Paragraph } = Typography
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showNewsLetter: false,
            showConferenceModal: false,
            subscriptionStatus: false,
            promo: null,
            showPromo: false,
            caller: {
                id: '',
                name: 'Unknown',
                picture: DEFAULT_PROFILE_PICTURE
            },
            conferenceId: null,
        };
        this.eventBinded = false;
        this.refreshToken = this.refreshToken.bind(this);
        this.bindFirebaseEvent = this.bindFirebaseEvent.bind(this);
        this.handleFirebaseNotification = this.handleFirebaseNotification.bind(this);
        this.fetchNotifications = this.fetchNotifications.bind(this);
        this.user = this.props.user ? this.props.user : this.props.tech;
    }

    componentDidMount() {
        // if (this.user == null && window.location.pathname == '/' && this.props.admin == null) {
        //     setTimeout(function () {
        //         this.setState({ showNewsLetter: true })
        //     }.bind(this), 10000)
        // }

        const tagManagerArgs = {
            gtmId: GTM_ID,
            dataLayer: {
                user_id: this.user != null ? this.user.id : null,
                org_id: this.user != null ? this.user.orgId : null
            }
        }
        TagManager.initialize(tagManagerArgs)
        this.bindFirebaseEvent();
        this.handleFirebaseNotification(); 
        this.tokenCheckInterval = setInterval(this.refreshToken, 600000); // refresh token after 10 minutes (milliseconds)
        this.refreshToken();
    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts
        if (this.tokenCheckInterval) {
            clearInterval(this.tokenCheckInterval);
        }
    }

    fetchNotifications(){
        ApiBaseHelper.get({
            url: `notification/in-app`, headers: {
                Authorization: 'Bearer ' + this.user?.token
            }
        }).then((res) => {
            let numOfUnreadNotifications =  res?.data.data?.filter((ele) => ele.Read === "0")?.length
            const sortedNotificationData =  [...res?.data.data]
            sortedNotificationData?.sort((a, b) => moment(b.Timestamp).local().diff(moment(a.Timestamp).local()))
            this.props.dispatch(updateInAppNotifications({
                unReadNotifications: numOfUnreadNotifications,
                notificationData: sortedNotificationData
            }))
        }).catch((error) => {
            console.error(error)
        })       
    }
    

    async handleFirebaseNotification() {
        try {
            const hasFirebaseMessagingSupport = await isSupported();
            if(hasFirebaseMessagingSupport){
                let fcmToken = await FirebaseHelper.getFcmToken();
                this.props.dispatch(updateFcmToken(fcmToken));
                if(fcmToken != null){
                    await FirebaseHelper.handleNotification({
                        onReceive: (payload) => {
                            notification.open({
                                message: 'TecMe',
                                description: payload.notification.body,
                                icon: <Image loading="lazy" src={"/images/favicon.png"} height={25} />,
                                onClick: (e) => {
                                    if (payload.data && payload.data.click_action)
                                        window.open(payload.data.click_action, '_blank');
                                }
                            })
                            this.fetchNotifications()
                        }
                    });
                }
            }
        } catch (err) {
            console.error('Failed to initialize Firebase Messaging', err);
        }
    }

    bindFirebaseEvent() {

        if (this.user != null && this.eventBinded == false) {
            FirebaseHelper.event({
                path: `users/${this.user.id}`,
                callback: (snapshot) => {
                    const conferenceId = snapshot.val();
                    if (conferenceId != null) {
                        FirebaseHelper.get({ path: `conferences/${conferenceId}` }).then((response) => {
                            if (response.exists()) {
                                const conferenceData = response.val();
                                if (conferenceData.declined == false && conferenceData.accepted == false)
                                    this.setState({
                                        conferenceId,
                                        caller: conferenceData.caller,
                                        showConferenceModal: true
                                    });
                                else {
                                    this.setState({
                                        showConferenceModal: false
                                    })
                                }
                            }
                            else {
                                this.setState({
                                    showConferenceModal: false
                                })
                            }
                        }).catch((e) => {
                            this.setState({
                                showConferenceModal: false
                            })
                        });
                    }
                    else {
                        this.setState({
                            showConferenceModal: false
                        })
                    }
                }
            });
            this.eventBinded = true;
        }
    }

    refreshToken() {
        let lastRefreshTokenTimestamp = localStorage.getItem("lastRefreshTokenTimestamp");
        if(lastRefreshTokenTimestamp != null){
            // divide it by 3600 x 1000 to convert it to HOURS
            let differenceInMiliseconds = moment().diff(moment(lastRefreshTokenTimestamp)) / 3600000;
            if(differenceInMiliseconds < 8)
                return;
        }
        let accessToken = this.props.tech ? this.props.tech['token'] : this.props.user ? this.props.user['token'] : null;
        if (accessToken != null) {
            ApiBaseHelper.post({
                url: `user/refresh-token`, headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then((res) => {
                if (this.props.tech != null) {
                    this.props.dispatch(updateTech({
                        ...this.props.tech,
                        token: res.data['data']['token']
                    }));
                } else {
                    this.props.dispatch(updateUser({
                        ...this.props.user,
                        token: res.data['data']['token']
                    }));
                }
                localStorage.setItem("lastRefreshTokenTimestamp",moment());
            }).catch((res) => {
                notification['error']({
                    message: 'Info',
                    description:
                        'Your session has expired.',
                });
                let user = this.props.user ? this.props.user : this.props.tech;
                if (user) {
                    // FirebaseHelper.detachAllEventListeners({ path: `users/${user.id}` });
                    this.props.dispatch(updateTech(null));
                    this.props.dispatch(updateUser(null));
                }
            });
        }
    }

    render() {
        const { showConferenceModal, caller} = this.state;

        const subdomain = window.location.hostname.split('.').length >= 2 ? window.location.hostname.split('.')[0] : null

        return <Router>
            <Suspense fallback={<PageFallback />}>
                <PromoModal visible={this.state.showPromo} promo={this.state.promo} close={() => { this.setState({ showPromo: false }) }} />
                <Modal
                    visible={this.state.showNewsLetter}
                    style={{ padding: '0 1rem', borderRadius: '0.5rem' }}
                    footer={false} width={800} onCancel={() => {
                        localStorage.setItem('time', moment().add(24, 'hours').format("YYYY-MM-DD"));
                        this.setState({ showNewsLetter: false })
                    }}>
                    <Row gutter={[16, 16]} align="middle" justify="center" style={{ padding: '1rem auto' }}>
                        <Col xs={0} sm={0} md={11} lg={11} xl={11} >
                            <Image loading="lazy" preview={false} src={NewsLetterImage} style={{ padding: '2rem 1rem', marginTop: '1rem' }} />
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={1} xl={1} ></Col>
                        <Col xs={24} sm={24} md={10} lg={12} xl={12} >
                            <Image loading="lazy" src={Logo} preview={false} style={{ width: '150px', margin: '1rem 0', }} />
                            <Title level={2} style={{ color: '#474747' }}>Save 10% on your next booking!</Title>
                            <Paragraph style={{ fontSize: '18px' }}>Subscribe to our newsletter for tech tips, updates, and special offers.</Paragraph>
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={(value) => {
                                    localStorage.setItem('time', moment().add(24, 'hours').format("YYYY-MM-DD"));
                                    this.setState({ subscriptionStatus: true })
                                    ApiBaseHelper.post({
                                        url: 'web/newsletter',
                                        payload: {
                                            email: value.email
                                        }
                                    }).then((res) => {

                                        this.setState({ subscriptionStatus: false, showNewsLetter: false, promo: res.data.data.promo_code, showPromo: true })
                                        notification['success']({
                                            message: 'TecMe',
                                            description:
                                                res.data.message,
                                        });
                                    }).catch((e) => {
                                        this.setState({ subscriptionStatus: false, showNewsLetter: false })
                                        console.log(e)
                                    })

                                }}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            required: true,
                                            message: 'Please enter a valid email address',
                                        },
                                    ]}
                                >
                                    <Input size={"large"} style={{ border: '1px solid #858585' }} prefix={<img loading="lazy" src={MessageIcon} style={{ marginRight: '0.5rem' }} />} placeholder="e.g johndoe@example.com" />

                                </Form.Item>
                                <Button htmlType="submit" loading={this.state.subscriptionStatus} type={"primary"} style={{ width: '140px', marginTop: '1rem' }}>
                                    Subscribe

                                </Button>

                            </Form>
                        </Col>
                    </Row>
                </Modal>
                <Modal centered visible={showConferenceModal} title={false} footer={false} closable={false}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} align="middle" justify="center">
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} align={"center"}>
                                    <img loading="lazy" style={{ zIndex: 100 }} src={caller.picture || DEFAULT_PROFILE_PICTURE} className="calling-image" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Typography.Title level={4}>{caller.name}</Typography.Title>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <p>is calling you</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row gutter={[10, 10]}>
                                <Col flex={1}>
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                showConferenceModal: false
                                            });
                                            let updates = {};
                                            updates[`conferences/${this.state.conferenceId}/declined`] = true
                                            FirebaseHelper.update({
                                                updates
                                            });
                                        }}
                                        type="default"
                                        style={{ height: 50 }} className="rounded w-100">DECLINE</Button>
                                </Col>
                                <Col flex={1}>
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                showConferenceModal: false
                                            });
                                            let updates = {};
                                            updates[`conferences/${this.state.conferenceId}/accepted`] = true
                                            FirebaseHelper.update({
                                                updates
                                            });
                                            window.open(ROUTES.VIDEO_CONFERENCING.replace(':channel', this.state.conferenceId), '_blank');
                                        }}
                                        type="primary"
                                        style={{ height: 50 }} className="rounded w-100">ACCEPT</Button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Modal>

                <Switch>
                    /* Admin routes*/
                    <LayoutNoHeaderFooter exact path={ROUTES.ADMIN_LOGIN} component={AdminLogin} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_USERS} component={AdminUsers} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_GROUP_CONVERSATIONS} component={GroupConversations} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_USER_DETAILS} component={AdminUserDetails} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_SERVICES} component={AdminServices} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_ADD_SERVICE} component={AdminAddService} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_EDIT_SERVICE} component={AdminEditService} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_PROMO} component={AdminPromo} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_PROMO_ADD} component={AdminAddPromo} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_PROMO_EDIT} component={AdminEditPromo} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_TECH_APPLICATION} component={AdminTechApplication} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_TECH_APPLICATION_ADD} component={AdminAddTechApplication} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_TECH_APPLICATION_EDIT} component={AdminEditTechApplication} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_SKILLS} component={AdminSkills} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_SKILLS_ADD} component={AdminAddSkill} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_SKILLS_EDIT} component={AdminEditSkill} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_MANAGE_BOOKING} component={AdminManageBooking} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_MANAGE_BOOKING_VIEW} component={AdminManageBookingView} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_SCHEDULED_SERVICES} component={AdminScheduleServices} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_SCHEDULED_SERVICES_VIEW} component={AdminScheduleServicesView} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_CONTRACTS} component={AdminContracts} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_CONTRACT_DETAILS} component={AdminContractDetails} />
                    <AdminProtectedLayout exact path={ROUTES.ADMIN_TICKET_DETAILS} component={AdminTicketDetails} />

                    /* public routes */
                    
                    {
                        (subdomain !== null && subdomain !== "development" && subdomain !== "staging" && subdomain !== "acceptance" && subdomain !== "app") ?
                        <ItcLayout exact path="/" component={ItcLandingPage} /> :
                        <ModernLayout exact path="/" component={DefaultPage} />
                    }    
             

                    {/*<ModernLayout exact path={ROUTES.CONTACT} component={Contact}/>*/}
                    {/*<ModernLayout exact path={ROUTES.LATEST_PRESS} component={PressPage}/>*/}
                    {/*<ModernLayout exact path={ROUTES.PRESS_LISTINGS} component={PressListings}/>*/}
                    {/*<ModernLayout exact path={ROUTES.ABOUT} component={AboutPage} />*/}
                    {/*<ModernLayout exact path={SOLUTIONS.map(record => `/solutions/${record.route.toLowerCase()}`)} component={ItSolutionsPage} />*/}
                    {/*<ModernLayout exact path={INDUSTRIES.map(record => `/solutions/${record.route.toLowerCase()}`)} component={ItIndustry} />*/}
                    {/*<DefaultLayout exact path="/old" component={NewHome} />*/}
                    
                    <UtilityLayout exact path={ROUTES.LOGIN} component={SignIn}  header={true}/>
                    <UtilityLayout exact path={ROUTES.TEAM_LOGIN} component={TeamLogin} header={true}/>
                    <UtilityLayout exact path={ROUTES.SIGN_UP} component={SignUp} header={false}/>
                    <UtilityLayout exact path={ROUTES.GUEST_SIGN_UP} component={GuestSignUp} header={true}/>
                    <UtilityLayout exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} header={true} />
                    <UtilityLayout exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} header={true}/>
                    <UtilityLayout exact path={ROUTES.ACCEPT_INVITE} component={AcceptInvite} header={true}/>

                    {/*<DefaultLayout exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />*/}
                    {/*<DefaultLayout exact path={ROUTES.TERM_OF_SERVICES} component={TermsAndConditions} />*/}
                    {/*<DefaultLayout exact path={ROUTES.BUG_REPORT} component={ReportABug} />*/}
                    <UtilityLayout exact path={ROUTES.SET_PASSWORD} component={SetPassword} />
                    <UtilityLayout exact path={ROUTES.CLIENT_ONBOARDING} component={ClientOnboarding} header={false}/>
                    <UtilityLayout exact path={ROUTES.TECH_APPLICATION} component={OnBoarding} />
                    <UtilityLayout exact path={ROUTES.STRIPE_CONNECT} component={StripeConnect} />
                    <UtilityLayout exact path={ROUTES.TECH_STATES} component={StatePage} />
                    <UtilityLayout exact path={STATES.map(state => `/${state.State.toLowerCase().replaceAll(" ", "-")}`)} component={CityPage} />
                    <UtilityLayout exact path={ROUTES.TECH_STATE_AND_CITY} component={SelectedCityPage} />
                    <UtilityLayout exact path={ROUTES.CREATE_SEND_CONTRACT} component={CreateSendContract} />


                    <LayoutNoHeaderFooter exact path={ROUTES.HIPA_TRAINING} url={"https://site.tecme.io/hipaa-training"} component={ExternalPage} />
                    <ProtectedLayout exact path={ROUTES.BOOK_SERVICES} component={BookService} />
                    <ProtectedLayout exact path={ROUTES.QUICK_BOOK} component={QuickBook} />
                    <ProtectedLayout exact path={ROUTES.BOOK_SUBSCRIPTION} component={BookSubscription} />
                    <ProtectedLayout exact path={ROUTES.DISCOVERY_EXPERIENCE_SEARCH} component={InstantSearch} />
                    <ItcLayout exact path={ROUTES.ITC_HOME} component={ItcLandingPage} />


                    /* customer routes */
                    <ProtectedLayout exact path={ROUTES.PAYMENT} component={Payment} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.ADD_PAYMENT} component={AddPayment} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.LOCATION} component={Location} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.ADD_NEW_SERVICE} component={AddNewServiceCustomer} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.DEVICES} component={Devices} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.ADD_DEVICES_NEW} component={AddEditDevice} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.EDIT_DEVICES_NEW} component={AddEditDevice} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.ADD_LOCATION} component={AddLocation} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.EDIT_LOCATION} component={AddLocation} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.SAVED_PROFILES} component={SavedProfilesList} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.SAVED_PROFILES_DETAILS} component={SavedProfileDetails} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.TEAM_MANAGEMENT} component={TeamManagement} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.JOB_POSTINGS} component={JobPostings} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.JOB_APPLICATIONS} component={JobApplications} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.ADD_JOB_POSTING} component={AddJobPosting} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.EDIT_JOB_POSTING} component={EditJobPost} userRoute="client" />
                    <ProtectedLayout exact path={ROUTES.MANAGE_JOB_POSTING} component={ManageJobPosting} />
                    <ProtectedLayout exact path={ROUTES.VIEW_JOB_APPLICATION} component={ViewApplication} />
                    <ProtectedLayout exact path={ROUTES.EDIT_JOB_APPLICATION} component={SubmitJobApplication} />
                    <ProtectedLayout exact path={ROUTES.JOB_POST} component={JobPost} />
                    <ProtectedLayout exact path={ROUTES.SUBMIT_JOB_APPLICATION} component={SubmitJobApplication} />
                    <ProtectedLayout exact path={ROUTES.MANAGE_JOB_APPLICATIONS} component={ManageJobApplications} />
                    <ProtectedLayout exact path={ROUTES.JOB_APPLICATION_DETAILS} component={JobApplicationDetails} />
                    <ProtectedLayout exact path={ROUTES.BROWSE_JOBS} component={JobBrowsing} />
                    {
                        this.props.user ? 
                        <ProtectedLayout exact path={ROUTES.DISCOVERY_EXPERIENCE} component={DiscoveryExperience} /> : 
                        <LayoutNoHeaderFooter exact path={ROUTES.DISCOVERY_EXPERIENCE} component={DiscoveryExperience} />
                    }
                    {
                        (this.props.user || this.props.tech) ? <ProtectedLayout exact path={ROUTES.TECH_PROFILE_WITH_ID} component={TechProfileNew} /> : <UtilityLayout exact path={ROUTES.TECH_PROFILE_WITH_ID} component={TechProfileNew} header={true} />
                    }
                    {
                        (this.props.user || this.props.tech) ? <ProtectedLayout exact path={ROUTES.TECH_PROFILE_NEW} component={TechProfileNew} /> : <UtilityLayout exact path={ROUTES.TECH_PROFILE_NEW} component={TechProfileNew} header={true} />
                    }
                    {
                        (this.props.user || this.props.tech) ? <ProtectedLayout exact path={ROUTES.TECH_IT_SERVICE} component={TechnicianService} /> : <UtilityLayout exact path={ROUTES.TECH_IT_SERVICE} component={TechnicianService} header={true} />
                    }

                    /* tech routes */
                    <ProtectedLayout exact path={ROUTES.MANAGE_SCHEDULE} component={ManageSchedule} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.CERTIFICATION} component={Certification} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.ADD_SERVICES} component={AddService} userRoute="tech"  />
                    <ProtectedLayout exact path={ROUTES.MANAGE_SKILLS} component={ManageSkills} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.CREATE_SPECIALIZATION} component={CreateSpecialization}userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.UPDATE_SPECIALIZATION} component={UpdateSpecialization} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.MY_SERVICE} component={MyService} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.EDIT_SERVICES} component={EditService} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.REVIEW_INVOICE} component={ReviewInvoice} userRoute="tech"/>
                    {/*<DefaultLayout exact path={ROUTES.CO_MARKETING_PLAN} component={CoMarketingPlan} />*/}
                    <ProtectedLayout exact path={ROUTES.MANAGE_PROFILE_DETAILS} component={ManageProfile} userRoute="tech" />
                    <UtilityLayout exact path={ROUTES.CREATE_ITC} component={CreateItc} header={false} userRoute="tech"/>
                    <ProtectedLayout exact path={ROUTES.ITC_PROFILE} component={ItcProfile} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.ITC_PROFILES} component={ItcProfiles} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.ITC_INVITES_LISTING} component={InviteListings} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.ITC_INVITE_DETAILS} component={InviteDetails} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.ITC_DASHBOARD} component={ItcDashboard} userRoute="tech" />
                    <ProtectedLayout exact path={ROUTES.ITC_TECH_TEAM} component={ItcTechTeam} userRoute="tech" />

                 
                    /* customer & tech common private routes */
                    <ProtectedLayout exact path={ROUTES.DASHBOARD} component={UserDashboard} />
                    <ProtectedLayout exact path={ROUTES.TICKET_LISTING} component={TicketListing} />
                    <ProtectedLayout exact path={ROUTES.MANAGE_BOOKING} component={HomePage}/>  
                    <ProtectedLayout exact path={ROUTES.INVOICE_LISTING} component={InvoiceListing} />
                    <ProtectedLayout exact path={ROUTES.MANAGE_BOOKING} component={ManageBooking} />
                    <ProtectedLayout exact path={ROUTES.BOOKING_DETAILS} component={ManageBooking} />
                    <ProtectedLayout exact path={ROUTES.BOOKING_DETAILS_NEW} component={BookingDetails} />
                    <ProtectedLayout exact path={ROUTES.EMAIL_VERIFICATION} component={EmailVerification} />
                    <ProtectedLayout exact path={ROUTES.SERVICE_DETAILS} component={ServiceDetails} />
                    <ProtectedLayout exact path={ROUTES.INBOX} component={RealTimeMessaging} />
                    <ProtectedLayout exact path={ROUTES.INBOX_2} component={RealTimeMessaging} />
                    <ProtectedLayout exact path={ROUTES.PROFILE_UPDATE} component={ProfileUpdate} />
                    {/*<ProtectedLayout exact path={ROUTES.INVOICE} component={Invoice} />*/}
                    <ProtectedLayout exact path={ROUTES.INVOICE} component={NewInvoice} />
                    <LayoutNoHeaderFooter exact path={ROUTES.VIDEO_CONFERENCING} component={VideoConferencing} />
                    <ProtectedLayout exact path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
                    <ProtectedLayout exact path={ROUTES.ACCOUNT_SETTING} component={AccountSettings} />
                    <ProtectedLayout exact path={ROUTES.CONTRACTS} component={Contracts} />
                    <ProtectedLayout exact path={ROUTES.VIEW_EDIT_CONTRACT} component={ViewEditContract} />
                    <ProtectedLayout exact path={ROUTES.TICKET_DETAILS} component={TicketDetails} />
                    <ProtectedLayout exact path={ROUTES.REPORTS} component={Reports} />
                    <ProtectedLayout exact path={ROUTES.DISCOVER_ITC} component={DiscoverItc} />

                    <DefaultLayout component={PageNoFound} />

                </Switch>

            </Suspense>
        </Router>
    }

}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(App);

const ModernLayout = ({ component: Component, ...rest }) => {
    useEffect(() => {
        document.body.style.overflow = "scroll";
    }, [])

    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className="mainbody">
                    <NewNavbar {...matchProps} />
                    <Component {...matchProps} {...rest} />
                    <NewFooter {...matchProps} />
                </div>
            )}
        />
    );
};

const ItcLayout = ({ component: Component, ...rest }) => {
    useEffect(() => {
        document.body.style.overflow = "scroll";
    }, [])

    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className="mainbody">
                    {/* <ItcNav {...matchProps} /> */}
                    <Component {...matchProps} {...rest} />
                    {/* <ItcFooter {...matchProps} /> */}
                </div>
            )}
        />
    );
};

const UtilityLayout = ({ component: Component, header, userRoute, ...rest }) => {
    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const [ currentPath, setCurrentPath ] = useState('')
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(user && userRoute === 'tech') {
            history.push(currentPath)
            notification.error({
                message: 'TecMe',
                description: 'You are not authorized to access this page as a client, please switch to your technician account.',
                duration:10
            })
        }
        if(tech && userRoute === "client"){
            history.push(currentPath)
            notification.error({
                message: 'TecMe',
                description: 'You are not authorized to access this page as a technician, please switch to your client account.',
                duration:10
            })
        }
    }, [userRoute, user])

    useEffect(() => {
        setCurrentPath(pathname)
    }, [])

    useEffect(() => {
        document.body.style.overflow = "scroll";
    }, [])

    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className="mainbody">
                    {
                        header && <UtilityHeader />
                    }
                    <Component {...matchProps} {...rest} />
                </div>
            )}
        />
    );
};

const DefaultLayout = ({ component: Component, ...rest }) => {
    useEffect(() => {
        document.body.style.overflow = "scroll";
    }, [])

    let isFooterSectionAllowed = true;
    
    switch (rest.path) {
        case ROUTES.DISCOVERY_EXPERIENCE_SEARCH:
            isFooterSectionAllowed = false
            break;
        case ROUTES.CREATE_SEND_CONTRACT:
            isFooterSectionAllowed = false
            break;
        default:
            break;
    }

    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className="mainbody">
                    <NewNavbar {...matchProps} />
                    <div style={{width:'100%',height:110}}>

                    </div>
                    <Component {...matchProps} {...rest} />
                    {isFooterSectionAllowed && (
                        <NewFooter {...matchProps} />
                    )}
                </div>
            )}
        />
    );
};

const AdminProtectedLayout = ({ component: Component, ...restOfProps }) => {
    const admin = useSelector(state => state.admin ? state.admin : false)
    return (
        <Route
            {...restOfProps}
            render={(matchProps) =>
                admin ? <div className="mainbody">
                    <SideNavigation
                        {...matchProps}
                        component={<Component {...matchProps} {...restOfProps} />}
                    />

                </div> : <DefaultLayout component={PageNoFound} />
            }
        />
    );
}

const LayoutNoHeaderFooter = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.user)
    const [windowSize, setWindowSize] = useState(getWindowSize())

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
    useEffect(() => {
    function handleWindowResize() {
        setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    }, []);

    return (
        <Route
            {...rest}
            render={matchProps => (
                <div>
                    <Component {...matchProps} {...rest} />
                </div>
            )}
        />
    );
};
