import React, {Component, Fragment, PureComponent} from 'react'
import {Row, Col, Popover, Layout, Input, Button, Card, Skeleton, Modal, Spin, notification, Typography, Space, Table, Tag, List, Badge, Tooltip} from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {connect} from "react-redux";
import {ApiBaseHelper} from "../../../../../utils/api-base-helper";
import NoLocationFound from './assets/images/location-ui-whitebg.png';
import {DeleteOutlined, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ActionIcon from  './assets/icons/action-icon.svg';
import {Link} from "react-router-dom";
import ROUTES from '../../../../../utils/routes';
import {LocationRepository} from './repository/location-repository'
import {STATES} from '../../../../../utils/us-states-city';
import {META_DESCRIPTION} from "../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import CustomRenderEmpty from '../../../../components/custom-render-empty'; 
import PageTitle from '../../../../components/page-title';
import SearchInput from '../../../../components/search-input';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import AddEditLocationModal from './add-location/modal';
import OfficeImage from './add-location/assets/images/Office.png'
import HomeImage from './add-location/assets/images/Home.png'
import AppartmentImage from './add-location/assets/images/Appartment.png'
import './assets/css/index.css'

const {confirm} = Modal;
const {Content} = Layout;
const {Paragraph, Title, Text} = Typography
const listData = [];


class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showData: [],
            deleteLoading: false,
            formStatus: false,
            dataLoading: false,
            sortedInfo: null,
            deleteModal: false,
            selectedLocation: "",
            locationDetailsModalVisible:false,
            selectedRecord:{},
            loadingFrame:true,
        };
        const locationsPopoverContent = (item) => {
            return(
                <div className="locations-popover-content">   
                    <Space
                    onClick = {() => 
                        this.setState({selectedRecord:item, locationDetailsModalVisible:true
                    })}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <EyeOutlined />
                            <Paragraph style={{marginBottom:0, whiteSpace:"nowrap", marginLeft: 8}}>View Location</Paragraph> 
                        </div>
                    </Space>
                    <Space className='popover-action-item' align='center'>
                        <Link 
                            onClick={(e) => {
                                e.stopPropagation();
                            }} 
                                to={{pathname: ROUTES.LOCATION, search: '?option=edit', state: { item }}}
                                style={{textDecoration:"none", display:"flex", whiteSpace:"nowrap"}}
                                >
                                <EditOutlined style={{ marginRight: 8, color: "#000000D9" }}/>
                                <Paragraph style={{marginBottom:0}}>Edit Location</Paragraph>
                        </Link>
                    </Space>
                    <Space 
                        onClick={(e) => {
                            this.setState({selectedLocation: item, deleteModal: true})
                            e.stopPropagation()
                            }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlined />
                            <Paragraph style={{marginBottom:0, marginLeft: 8}}>Delete Location</Paragraph> 
                        </div>
                    </Space>
                
                </div>
            )
        }
        this.columns = [
            {
                title: 'Address',
                dataIndex: 'Address_Full',
                width: '25%',
                sorter: {
                    compare: (a, b) => a.Address_Full.localeCompare(b.Address_Full),
                    multiple: 1,
                },
                render:(_, record) => {
                    return (
                        <Space>
                            <Text>{capitalizeFirstLetter(record.Address_Full)}</Text>
                            {this.props.user.tech === true && ( 
                                record.Id === this.props.user.Primary_Facility.Id && 
                                    <Tooltip title="This is your primary location.">
                                        <Badge count="primary" />
                                    </Tooltip>
                                
                            )}
                        </Space>
                    )
                },
                web: true,
                mobile: true
            },
            {
                title: 'City',
                dataIndex: 'City',
                width: '10%',
                sorter: {
                    compare: (a, b) => a.City.localeCompare(b.City),
                    multiple: 1,
                },
                render:(_, record) => {
                    return (
                        <Text>{capitalizeFirstLetter(record.City)}</Text>
                    )
                },
                web: true,
                mobile: true
            },
            {
                title: 'State',
                dataIndex: 'State',
                width: '10%',
                sorter: {
                    compare: (a, b) => a.State.localeCompare(b.State),
                    multiple: 1,
                },
                web: true,
                mobile: true,
                // render: (State) => <div >{STATES.map(value => {
                //     if (value.Code == State) {
                //         return value.State
                //     }
                // })}</div>

            },
            {
                title: 'Type',
                dataIndex: 'Type',
                sorter: {
                    compare: (a, b) => a.Type.localeCompare(b.Type),
                    multiple: 1,
                },
                web: true,
                mobile: false,
                width: '15%',
                render: (Type) => <div><p style={{
                    backgroundColor: '#FFF2E8',
                    margin: 0,
                    color: '#DB5E3F',
                    border: '1px solid #FEBA98',
                    width: 'max-content',
                    padding: '0 0.5rem',
                    alignItems: 'center'
                }}>{Type}</p></div>
            },
            {
                title: 'Action',
                width: '15%',
                dataIndex: 'Id',
                key: 'Id',
                web: true,
                mobile: true,
                render: (text, record) => <div style={{display: 'flex', alignItems: 'center', columnGap:'20px'}}>
                    <Link
                          style={{color:"#F04A22", textDecoration:"none", border:"1px solid #F04A22", padding:"3px 15px", borderRadius:"2px"}}
                        onClick={(e) => e.stopPropagation()}
                        to={{pathname: ROUTES.LOCATION, search:'?option=edit', state: {record}}}>Edit</Link>
                    <div>   
                        {   
                             <Text   
                             style={{color:"#F04A22"}}  
                             onClick={(e) => {
                                this.setState({selectedLocation: record, deleteModal: true})
                                e.stopPropagation()
                            }}>Delete</Text>
                        }
                    </div>
                </div>,
            }
        ];

        this.openNotification = (value) => {
            notification.open({
                message: value.message,
                description: value.description,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        };
        this.searchData = [];
        this.locationRepository = new LocationRepository(this.props.user?.token)
        this.display = (responsive) => {

            return (
                <CustomRenderEmpty
                       description={"Save your device locations to quickly book on-site services in the future."} 
                       extra={  
                        <Button onClick={() => {
                            this.props.history.push({pathname:ROUTES.LOCATION,  search:'?option=add'})
                        }} block >
                            + Add Location 
                        </Button>
                           }>

                    {
                        responsive == true ?
                        <Table 
                            onRow={(record) => {
                                return {
                                    onClick: () => this.setState({
                                        selectedRecord:record,
                                        locationDetailsModalVisible:true
                                    })
                                };
                            }}
                            sticky={true}
                            grid={{
                                gutter: 16,
                                xs: 0,
                                sm: 0,
                                md: 0,
                                lg: 1,
                                xl: 1,
                                xxl: 1,
                            }}
                            rowKey="Id" 
                            showSorterTooltip={false} 
                            columns={this.columns} 
                            dataSource={this.state.showData} 
                            scroll={{x: '100%'}} 
                            loading={this.state.dataLoading || this.state.deleteLoading}
                        />
                        :
                        <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                        }}
                        dataSource={this.state.showData}
                        loading={this.state.dataLoading || this.state.deleteLoading}
                        columns={this.columns}
                        rowKey="Id" 
                        pagination={{
                        onChange: page => {
                            window.scrollTo({
                            top:0,
                            behavior:"smooth"
                            })
                        },
                    pageSize: 10,
                     }}
                    renderItem={
                        item => {
                         return (
                            <List.Item 
                            //onClick = {() => this.setState({selectedRecord:item, locationDetailsModalVisible:true})} 
                            key={item?.id} >     
                                <Card style={{width: "100%", minHeight:"210px"}}>
                                    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                                        <div style={{display:"flex", justifyContent:"space-between"}}>
                                            <div style={{color:"#000000", fontSize:18, fontWeight:"bold"}}>
                                                {capitalizeFirstLetter(item.Address_Full)}
                                            </div>
                                            <Popover content={locationsPopoverContent(item)}  trigger="click" placement='bottom' overlayClassName="locations-popover" arrowPointAtCenter>
                                                <img src={ActionIcon} 
                                                alt="action icon" 
                                                onClick={
                                                    (e) => {
                                                        e.stopPropagation()
                                                    }}/>  
                                            </Popover>
                                        </div>
                                        <Space>
                                            <Paragraph style={{margin:0}}>{capitalizeFirstLetter(item.City)},&nbsp;{capitalizeFirstLetter(item.State)}</Paragraph>
                                            {this.props.user.tech === true && (
                                                item.Id === this.props.user.Primary_Facility.Id &&
                                                <Tooltip
                                                    title="This is your primary location."
                                                >
                                                    <Badge count="primary"/>
                                                </Tooltip>
                                            )}
                                        </Space>
                                        <p style={{
                                            backgroundColor: '#FFF2E8',
                                            margin: 0,
                                            color: '#DB5E3F',
                                            border: '1px solid #FEBA98',
                                            width: 'max-content',
                                            padding: '0 0.5rem',
                                            alignItems: 'center'
                                        }}>{item.Type}</p>
                                    </Space>
                                </Card>
                            </List.Item>
                    )}

                }
                />

                    }      
               </CustomRenderEmpty>
            )
        }
        this.fetchLocations = this.fetchLocations.bind(this)
    }

    hideLoadingFrame = () => {
        this.setState({
            loadingFrame:false
        })
    }


    fetchLocations(){
        this.locationRepository.getLocations()
        .then(res => {
            const filteredLocation = res?.data?.data.reverse()
            this.setState({data: filteredLocation, dataLoading: false, showData: filteredLocation});
        })
        .catch(err => {
            this.setState({dataLoading: false});
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({dataLoading: true})
        this.fetchLocations()
    }

    render() {
        let {sortedInfo, filteredInfo} = this.state;
        sortedInfo = sortedInfo || {};
        let _this = this
        const params = new URLSearchParams(_this.props.location.search)
        return (
            <Fragment>
                <MetaTags>
                    <title>Manage Locations | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION}/>
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <AddEditLocationModal
                    open={params.get('option')} 
                    onOk={() => {
                        this.props.history.push(this.props.location.pathname)
                    }} 
                    onCancel={() => {
                        this.props.history.push(this.props.location.pathname)
                    }} 
                    history={this.props.history}
                    location={this.props.location}
                    option={params.get('option')}
                    fetchLocations={this.fetchLocations}
                />
                 <Modal   
                    title={`${capitalizeFirstLetter(this.state.selectedRecord?.Type)} Address`}
                    visible={this.state.locationDetailsModalVisible}
                    onOk={() => this.setState({locationDetailsModalVisible:false})}
                    onCancel={() => this.setState({locationDetailsModalVisible:false})}
                    width={800}
                    footer={null} 
                >
                    <Row gutter={[24, 24]} align="middle">
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            { 
                                (this.state.selectedRecord.Latitude || this.state.selectedRecord.Longitude) ?  <iframe
                                width={'100%'}
                                height={300}
                                style={{border: 0}}
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAA104TGF5d0eM8WoEylk-SyFjtzO4v5nM&q=${this.state.selectedRecord.Latitude},${this.state.selectedRecord.Longitude}`}>
                            </iframe> : <img className='device-image' src={this.state.selectedRecord?.Type === "Home" ? HomeImage : this.state.selectedRecord?.Type === "Office" ? OfficeImage : AppartmentImage} alt="" /> 
                            }
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Row gutter={[12, 12]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Full Address</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{capitalizeFirstLetter(this.state.selectedRecord?.Address_Full || '--')}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Type</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.Type || '--'}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>City</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.City || '--'}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>State</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.State || '--'}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Zip Code</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.Zip_Code || '--'}</Title>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal>
                <Modal visible={this.state.deleteModal} centered closable={false}
                        okText="Delete"
                       onOk={() => {
                           this.setState({
                               deleteModal: false,
                               deleteLoading: true

                           });
                           this.locationRepository.locationDelete(this.state.selectedLocation.Id).then((response) => {

                               notification['success']({
                                   message: 'TecMe',
                                   description:
                                       'Location deleted successfully',
                               });
                               let filterData = _this.state.data.filter(location => location.Id != this.state.selectedLocation.Id)
                               this.setState({
                                   data: filterData,
                                   showData: filterData,
                                   deleteLoading: false,

                               });


                           }).catch((error) => {
                               this.setState({
                                   deleteLoading: false,

                               })
                               console.log(error)
                           })
                       }}
                       onCancel={(e) => this.setState({deleteModal: false})}

                >
                    <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                    This action will permanently delete the location and cannot be undone. Please make sure you want to delete the item before proceeding. 
                    </Paragraph>
                </Modal>
                <Content className="main">
                    <div className="page">
                        <div style={{minHeight: '800px'}}>

                            <Row gutter={[15, 15]} justify={"center"}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div>
                                        <div>
                                            <Row gutter={[20, 20]} align={"middle"} justify={"space-between"}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <PageHeaderWrapper
                                                        routes={[
                                                            {
                                                                path: ROUTES.LOCATION,
                                                                breadcrumbName: 'Locations',
                                                            },
                                                        ]}
                                                        // extra={[
                                                        //     <Button
                                                        //         onClick={() => this.props.history.push(ROUTES.ADD_SERVICES)}
                                                        //         size="large"
                                                        //         className='button-background'>
                                                        //         <AddIcon fontSize="medium" />  Add New Service
                                                        //     </Button>
                                                        // ]}
                                                    />


                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                    <div className='auth-container' >
                                                        <div className="auth-page-ctn">
                                                            <PageTitle 
                                                                title="Locations" 
                                                                extra={ 
                                                                    <Button onClick={() => {
                                                                        this.props.history.push({pathname:ROUTES.LOCATION,  search:'?option=add'})
                                                                    }}>+ Add New Location </Button>
                                                                }
                                                                search={
                                                                    <SearchInput 
                                                                        placeholder="Search Locations"
                                                                        onChange={(event) => {
                                                                                this.searchData = this.state.data.filter((val) => {
                                                                                    if (event.target.value == "") {
                                                                                        return val
                                                                                    } else if (val.Address_Full.toLowerCase().includes(event.target.value.toLowerCase()) || val.City.toLowerCase().includes(event.target.value.toLowerCase()) || val.State.toLowerCase().includes(event.target.value.toLowerCase()) || val.Type.toLowerCase().includes(event.target.value.toLowerCase())) {

                                                                                        return val
                                                                                    }

                                                                                })

                                                                                this.setState({showData: this.searchData})
                                                                        }}
                                                                        />
                                                                } 
                                                            />
                                                            <Row gutter={[16, 16]}  >
                                                                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                                                                    {this.display(true)}
                                                                </Col>
                                                                <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                                    {this.display(false)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </Content>
            </Fragment>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(Location);
