import React, { useEffect, useState } from 'react'
import { Progress, Space, Typography } from 'antd'
import CreateItcHeader from './components/header'
import StepsLayout from '../../../customer/client-onboarding/components/steps-layout'
import ItcCompanyDetails from './components/company-details'
import ItcExtraCompanyDetails from './components/extra-company-details'
import Customization from './components/customization'
import ItcSpecializations from './components/specializations'
import CreateItcTestimonials from './components/testimonials'
import CreateItcFaq from './components/faq'
import Membership from './components/membership'
import TechTeam from './components/tech-team'
import ItcSuccess from './components/itc-success'
import CompanyDetailsImg from './assets/images/company-details-img.png'
import CustomizationImg from './assets/images/customization-img.png'
import SpecializationImg from './assets/images/specialization-img.png'
import TestimonialsImg from './assets/images/testimonials-img.png'
import FaqImg from './assets/images/faq-img.png'
import MembershipImg from './assets/images/membership-img.png'
import TeamImg from './assets/images/tech-img.png'
import BackIcon from './assets/images/back-icon.svg'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import UtilityHeader from '../../../../components/utility-header'
import Login from '../../../common/public/authentication/login'
import './assets/css/styles.scss'
import './assets/css/tech-card.scss'
import ExternalIntegrations from './components/external-integrations'


const {Paragraph, Title} = Typography

const CreateItc = () => { 
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const [progressIndex, setProgressIndex] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [progress, setProgress] = useState(0)
    const [showBackBtn, setShowBackBtn] = useState(true)
    const [itcId, setItcId] = useState(null)
    const [logoFile, setLogoFile] = useState(null)
    const [companyDetails, setCompanyDetails] = useState({
        name:null,
        is_project: false,
        subdomain:null,
        description:null,
        experience_years:1,
        linkedin_url:null,
        facebook_url:null,
        youtube_url:null,
        twitter_url:null,
        project_links:null,
        crm_form:null,
        kanban_board:null,
        brand_color:"#F04A22",
        membership_type:"one-time",
        membership_fee:null,
        brand_logo:null,
        popup:true,
    })
    const [businessLocation, setBusinessLocation] = useState({
        city:null,
        state:null,
        zip_code:null,
    })
    const [specializations, setSpecializations] = useState({
        category:null,
        title:null,
        description:null,
        work_type:null, 
        skills:[],
    })
    const [techTeam, setTechTeam] = useState([])
    const [locationId, setLocationId] = useState(null)

    const currentPageNum = currentIndex + 1

    const fetchTechTeam = () => {
        // setLoadingTeam(true)
        ApiBaseHelper.get({
            url: `itc/member/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + tech['token']
            }
        }).then((res) => {
            setTechTeam(res?.data?.data)
            // setLoadingTeam(false)
        }).catch((error) => {
            console.error(error)
            // setLoadingTeam(false)
        })       
    }

    useEffect(() => {
        if(itcId){
            fetchTechTeam()
        }
    }, [itcId])

    const progressSteps = [
        {
            step:1,
            title: "Company details",
            description: "Enter your company's basic information to establish your professional identity.",
        },
        {
            step:2,
            title: "Customization",
            description: `Personalize your ${companyDetails?.is_project ? 'Project' : 'ITSC'} profile.`
        },
        {
            step:3,
            title: "External Integrations",
            description: `Integrate external services to enhance your ${companyDetails?.is_project ? 'Project' : 'ITSC'}.`
        },
        {
            step:4,
            title: "Membership Fee",
            description: `Set membership fee for technicians, ensuring sustained participation and support in your ${companyDetails?.is_project ? 'Project' : 'ITSC'} network.`
        },
        {
            step:5,
            title: "Specializations",
            description: "Highlight your areas of expertise and the specialized services you offer."
        },
        {
            step:6,
            title: "Testimonials",
            description: "Showcase client feedback to build trust and credibility with potential customers."
        },
      
        {
            step:7,
            title: "FAQs",
            description: "Address common questions to provide clarity and confidence to your clients."
        },
      
        {
            step:8,
            title: "Build Team",
            description: "Build your team by inviting other tech professionals to collaborate and enhance your services."
        }, 
    ]

    
    const handleNextClick = () => {
        if(currentIndex < createSteps.length - 1){
            setCurrentIndex(currentIndex + 1)
            setProgress(((currentIndex+1) / stepsLength) * 100)
        }
        if(currentIndex >= 1 && progressIndex < progressSteps.length){
            setProgressIndex(progressIndex + 1)
        }
        window.scrollTo(0, 0)
    }

    const handleBackClick = () => {
        if(currentIndex > 0){
            setCurrentIndex(currentIndex - 1)
            setProgress(((currentIndex-1) / stepsLength) * 100)
            if(progressIndex > 0){
                setProgressIndex(progressIndex - 1)
            }
        }
    }

    const createStepsInit = [
        {
            step:1,
            content:<ItcCompanyDetails 
                handleNextClick={handleNextClick} 
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
            />,
            header:{
                title:"1. Company Details",
                tagline:"Establish Your Business Foundation",
                subTagline:"Enter your company's basic information to establish your professional identity.",
                image:CompanyDetailsImg
            }
        },
        {
            step:2,
            content:<ItcExtraCompanyDetails
                handleNextClick={handleNextClick} 
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
                businessLocation={businessLocation}
                setBusinessLocation={setBusinessLocation}
                setLocationId={setLocationId}
            />,
            header:{
                title:"1. Company Details",
                tagline:"Establish Your Business Foundation",
                subTagline:"Enter your company's basic information to establish your professional identity.",
                image:CompanyDetailsImg
            }
        },
        {
            step:3,
            content:<Customization
                handleNextClick={handleNextClick} 
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
                logoFile={logoFile}
                setLogoFile={setLogoFile}
            />,
            header:{
                title:`${currentIndex}. Customization`,
                tagline:"Brand Your ITSC Profile",
                subTagline:`Personalize your ${companyDetails?.is_project ? 'Project' : 'ITSC'} profile with brand color and logo to make it uniquely yours.`,
                image:CustomizationImg
            }
        },
        {
            step:4,
            content:<ExternalIntegrations
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
                handleNextClick={handleNextClick} 
                itcId={itcId}
            />,
            header:{
                title:`${currentIndex}. External Integrations`,
                tagline:`Integrate external services to enhance your ${companyDetails?.is_project ? 'Project' : 'ITSC'}`,
                subTagline:`To expand your ${companyDetails?.is_project ? 'Project' : 'ITSC'}'s capabilities, integrate external services like Zapier, Google Forms, and more.`,
                image:TeamImg
            }
        },
        {
            step:5,
            content:<Membership
                handleNextClick={handleNextClick} 
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
                businessLocation={businessLocation}
                itcId={itcId}
                setItcId={setItcId}
                logoFile={logoFile}
                locationId={locationId}
            />,
            header:{
                title:`${currentIndex}. Membership Fee`,
                tagline:"Secure Ongoing Commitment",
                subTagline:`Set a recurring or one-time membership fee for technicians, ensuring sustained participation and support in your ${companyDetails?.is_project ? 'Project' : 'ITSC'} network.`,
                image:MembershipImg,
            }
        },
        {
            step:6,
            content:<ItcSpecializations
                handleNextClick={handleNextClick} 
                setShowBackBtn={setShowBackBtn}
                itcId={itcId}
            />,
            header:{
                title:`${currentIndex}. Specializations`,
                tagline:"Define Your Expertise",
                subTagline:"Highlight your areas of expertise and the specialized services you offer.",
                image:SpecializationImg
            }
        },
        {
            step:7,
            content:<CreateItcTestimonials
                handleNextClick={handleNextClick} 
                setShowBackBtn={setShowBackBtn}
                itcId={itcId}
            />,
            header:{
                title:`${currentIndex}. Testimonials`,
                tagline:"Leverage Client Success Stories",
                subTagline:"Showcase client feedback to build trust and credibility with potential customers.",
                image:TestimonialsImg
            }
        },
        {
            step:8,
            content:<CreateItcFaq
                handleNextClick={handleNextClick} 
                itcId={itcId}
                setShowBackBtn={setShowBackBtn}
            />,
            header:{
                title:`${currentIndex}. FAQs`,
                tagline:"Inform and Engage Your Clients",
                subTagline:"Address common questions to provide clarity and confidence to your clients.",
                image:FaqImg
            }
        },
        {
            step:9,
            content:<TechTeam
                handleNextClick={handleNextClick} 
                techTeam={techTeam}
                setTechTeam={setTechTeam}
                itcId={itcId}
                fetchTechTeam={fetchTechTeam}
            />,
            header:{
                title:`${currentIndex}. Build your tech team`,
                tagline:"Expand Your Professional Network",
                subTagline:"Build your team by inviting other tech professionals to collaborate and enhance your services.",
                image:TeamImg
            }
        },
        {
            step:10,
            content:<ItcSuccess
                companyDetails={companyDetails}
                itcId={itcId}
            />,
        }
    ]

    let createSteps = companyDetails?.is_project ? createStepsInit.filter(step => step?.step !== 7) : createStepsInit

    const stepsLength = createSteps?.length - 1
   
    if (user == null && tech == null){
        return( 
            <>
                <UtilityHeader />
                <Login access={false}/>
            </>
        )
    }

    return (
    <StepsLayout
        progressSteps={companyDetails?.is_project ? progressSteps.filter(step => step.title.toLowerCase() !== "testimonials") : progressSteps}
        progressIndex={progressIndex}
        pageTitle={`Create ${companyDetails?.is_project ? 'Project' : 'ITSC'} Profile`}
        stepsTitle={`Create ${companyDetails?.is_project ? 'Project' : 'ITSC'} Profile`}
    >
        {  
            createSteps[currentIndex].header &&
            <CreateItcHeader 
                title={createSteps[currentIndex].header.title}
                tagline={createSteps[currentIndex].header.tagline}
                subTagline={createSteps[currentIndex].header.subTagline}
                image={createSteps[currentIndex].header.image}
            /> 
        }
        {
            (showBackBtn) &&
            <>
                {
                    (currentIndex > 0 && currentIndex < createSteps.length - 1) &&
                    <Space size={10}  onClick={handleBackClick} className='itc-back-btn' style={{cursor:"pointer"}}>
                        <img src={BackIcon} alt="Back Icon" />
                        <span>Go Back</span>
                    </Space>
                }
            </>
        }
        { 
            currentIndex < createSteps.length - 1 && 
            <div className="mobile-progress-bar-section">
                <div className="mobile-progress-bar">
                    <Progress percent={Math.round(progress)} />
                </div>
                <Paragraph style={{marginBottom:0, fontWeight:500, color:"rgba(0, 0, 0, 0.65)"}}>Step {currentIndex > 0 ? currentIndex : 1}</Paragraph>
            </div>
        }
        <div className="itc-main-form-content">
            {createSteps[currentIndex].content}
        </div>
    </StepsLayout>
  )
}



export default CreateItc