import React, { useEffect, useState } from 'react'
import { CustomizationForm } from '../../create-itc/components/customization'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { Button, Form, notification } from 'antd'
import { isValidUrl } from '../../../../../../utils/helper'
import { ExternalIntegrationsForm } from '../../create-itc/components/external-integrations'

const ExternalIntegration = ({itcDetails, itcId, token}) => {
    const [form] = Form.useForm();
    const [submittingDetails, setSubmittingDetails] = useState(false)
    
    const handleIntegrationSubmit = (values) => {
        setSubmittingDetails(true)
        ApiBaseHelper.put({
            url: `itc/${itcId}`,	
            headers: {
                Authorization: 'Bearer ' + token,
            },
            payload: {
                Company_Name: itcDetails?.Company_Name,
                CRM_Form: values?.crmForm,
                Kanban_Board: values?.kanbanBoard,
            }
        }).then((res) => {
            setSubmittingDetails(false)
            notification.success({
                message: 'TecMe',
                description: 'External integrations updated successfully'
            })
        }).catch((err) => {
            setSubmittingDetails(false)
            console.log(err)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while updating integrations details'
            })
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            crmForm: itcDetails?.custom_data?.crmForm,
            kanbanBoard: itcDetails?.custom_data?.kanbanBoard,
        })
    }, [itcDetails])

    return (
        <div className='integration-form-ctn'>
            <Form
                onFinish={handleIntegrationSubmit}
                form={form}
                layout='vertical'
                className="itc-company-details-form"
            >
                <ExternalIntegrationsForm isProject={itcDetails?.isProject}/>
                <Button
                    className='itc-next-btn' 
                    type='primary' 
                    loading={submittingDetails}
                    htmlType='submit'
                >Update</Button>
            </Form>
        </div>
    )
}

export default ExternalIntegration