import React, { useEffect, useState } from 'react'
import CreateItcHeader from './header'
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, notification, Row, Slider, Tooltip, Typography } from 'antd'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItcOptions from './itc-options'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { InfoCircleOutlined } from '@ant-design/icons';

const {Title, Paragraph} = Typography
const { useForm } = Form

const ItcCompanyDetails = ({handleNextClick, companyDetails, setCompanyDetails}) => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false)
    const [expYearsValue, setExpYearsValue] = useState(1)
    const [editorState, setEditorState] = useState("");


    const handleCompanyDetailsSubmit = (values) => {
        setCompanyDetails({
            ...companyDetails,
            name: values.companyName,
            is_project: values.isProject,
            subdomain: values.companyWebsite,
            description: draftToHtml(convertToRaw(editorState?.getCurrentContent())).replace(/&nbsp;/g, ' '),
            experience_years: expYearsValue,
            popup:false
        })
        if(draftToHtml(convertToRaw(editorState?.getCurrentContent())) === "<p></p>\n"){
            notification.error({
                message: 'TecMe',
                description: 'Please enter a description'
            })
            return
        }
        handleNextClick()
    }

    useEffect(() => {
        form.setFieldsValue({
            companyName: companyDetails?.name,
            companyWebsite: companyDetails?.subdomain,
            companyDescription: companyDetails?.description,
            isProject: companyDetails?.is_project
        })
        setExpYearsValue(companyDetails?.experience_years)
        const contentBlock = htmlToDraft(companyDetails?.description || "");
        const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState))
    }, [companyDetails])

    return (
        <div className='itc-company-details-section'>
            <div className="itc-company-details-ctn">
                <div className="itc-details-form-ctn">
                    <Title level={4} className="itc-form-title">Company details (1/2 steps)</Title>
                    <Form
                        onFinish={handleCompanyDetailsSubmit}
                        form={form}
                        layout='vertical'
                        className="itc-company-details-form"
                    >
                        <CompanyDetailsForm
                            expYearsValue={expYearsValue}
                            setExpYearsValue={setExpYearsValue}
                            editorState={editorState}
                            setEditorState={setEditorState}
                        />
                        <Form.Item  className='itc-company-details-form-item'>
                            <Button className='itc-next-btn' type='primary' htmlType='submit' loading={loading}>Next</Button>
                        </Form.Item> 
                    </Form>
                </div>
            </div>
           { 
                companyDetails?.popup &&
                <ItcOptions />
           }
        </div>
    )
}

export const CompanyDetailsForm = ({expYearsValue, setExpYearsValue, itcDetails, editorState, setEditorState, isFromItcProfile=false}) => {
    const tech = useSelector(state => state.tech)
  

    const onChange = (newValue) => {
        setExpYearsValue(newValue);
    };

    const websitePrefix = "https://";
    const websiteSuffix = ".tecme.io"
    const subdomainRegex = /^[a-zA-Z0-9-]+$/;	

    return (    
       <>
            <Form.Item  
                name="companyName"  
                label="Company Name" 
                className='itc-company-details-form-item'
                rules={[
                    { required: true, message: 'Please input your company name' },
                    {
                        validator: (_, value) => {
                            // Return the promise chain here
                            if(isFromItcProfile && itcDetails?.Company_Name?.toLowerCase() === value.toLowerCase()){
                                return Promise.resolve();
                            }
                
                            return ApiBaseHelper.get({
                                url: `itc/company/${value}`,
                                headers: {
                                Authorization: 'Bearer ' + tech?.token,
                                },
                            })
                            .then((res) => {
                                if ((value || '').length > 0 && !res.data.data) {
                                // Use `Promise.reject` to indicate validation failure
                                return Promise.reject(new Error('Company name already exists'));
                                }
                                // Use `Promise.resolve` to indicate validation success
                                return Promise.resolve();
                            })
                            .catch((err) => {
                                if (err.message === 'Company name already exists') {
                                    throw err;
                                }
                                return Promise.reject(new Error('Unable to validate company name'));
                            })
                        },
                    }
                ]}
            >
                <Input 
                    type="text" 
                    placeholder="Enter your company name" 
                    size="large"
                />
            </Form.Item> 
            <Form.Item
                className='itc-company-details-form-item'
                name="isProject"
                valuePropName='checked'
            >
                <Checkbox style={{fontWeight:500, fontSize:16}}>
                    Do you want to make this a Project profile?
                    <Tooltip title="A project profile is a streamlined version of an ITSC profile, specifically designed to facilitate the management of a defined set of tasks within an ITSC.">
                        <InfoCircleOutlined style={{marginLeft:10, position:'relative', top:3}}/>
                    </Tooltip>
                </Checkbox>
            </Form.Item>

            {/* <Form.Item  
                name="companyDescription"  
                label="Description" 
                className='itc-company-details-form-item'
                rules={[{ required: true, message: 'Please input your company description' }, 
                { min: 25, message: 'Minimum of 25 characters allowed'},
                { max: 1500, message: 'Maximum of 1500 characters allowed.' }]}
            >
                <Input.TextArea   
                    showCount
                    placeholder='Type here briefly what your company does'
                    maxLength={1500}
                    className='itc-company-details-form-textarea'
                    size='large'
                />
            </Form.Item>  */}
            <Form.Item  
                name="companyDescription"  
                label="Description" 
                className='itc-company-details-form-item'
                // rules={[{ required: true, message: 'Please input your company description' }, 
                // { min: 25, message: 'Minimum of 25 characters allowed'},
                // { max: 1500, message: 'Maximum of 1500 characters allowed.' }]}
            >
                <Editor
                    readOnly={false}
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbarHidden={false}
                    onEditorStateChange={(newEditorState) => {
                        setEditorState(newEditorState)
                    }}
                    toolbarStyle={{border:0, borderBottom:'1px solid #D9D9D9'}}
                    wrapperStyle={{minHeight: "35vh", border: '1px solid #D9D9D9'}}
                    toolbar={{
                        options: ['blockType', 'inline', 'list', 'link', 'colorPicker', 'emoji'],
                        list: {
                            options: ['unordered', 'ordered'],
                            blockType: {
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                            },
                        },
                    }}
                />
            </Form.Item>
            <Form.Item 
                name="companyWebsite"  
                label="ITSC subdomain" 
                className='itc-company-details-form-item'
                rules={[
                    { 
                        required: true, 
                        message: 'Please input your company subdomain' 
                    },
                    {
                        pattern: subdomainRegex,
                        message: 'Please enter a valid subdomain'
                    },
                    {
                        validator: (_, value) => {
                          // Return the promise chain here
                          if(isFromItcProfile && itcDetails?.Subdomain?.toLowerCase() === value?.toLowerCase()){
                            return Promise.resolve();
                          }
            
                          return ApiBaseHelper.get({
                            url: `itc/subdomain/${value}`,
                            headers: {
                              Authorization: 'Bearer ' + tech?.token,
                            },
                          })
                          .then((res) => {
                            if ((value || '').length > 0 && !res.data.data) {
                              // Use `Promise.reject` to indicate validation failure
                              return Promise.reject(new Error('Subdomain already exists'));
                            }
                            // Use `Promise.resolve` to indicate validation success
                            return Promise.resolve();
                          })
                        },
                    }
                ]}
            >
                <Input 
                    type="text" 
                    size="large"
                    className='itc-company-website-input'
                    addonBefore={websitePrefix}
                    addonAfter={websiteSuffix}
                />
            </Form.Item> 
            <Form.Item
                label="Number of Years in IT Industry"
                className='itc-company-details-form-item'
            >
                <Row>
                    <Col span={14}>
                        <Slider
                            min={1}
                            max={20}
                            onChange={onChange}
                            value={typeof expYearsValue === 'number' ? expYearsValue : 0}
                            trackStyle={{
                                backgroundColor: '#F58E75',
                            }}
                            handleStyle={{
                                borderColor: '#F58E75',
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            min={1}
                            max={20}
                            style={{
                                margin: '0 16px',
                            }}
                            value={expYearsValue}
                            onChange={onChange}
                            size='large'
                        />
                    </Col>
                </Row>
            </Form.Item>
       </>
            

    )
}


export default ItcCompanyDetails