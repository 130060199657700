import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Button, Card, Checkbox, Col, Collapse, Input, Layout, List, Modal, Popover, Radio, Row, Select, Slider, Space, Table, Tabs, Tag, Typography } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import './assets/css/styles.scss'
import { useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { getDurationInfo, getSpecializationTitle, getWorkTypeInfo } from '../../../common/private/job-post/utils/helper'
import { capitalizeFirstLetter } from '../../../../../utils/helper'
import CustomRenderEmpty from '../../../../components/custom-render-empty'
import ItcFilter from './components/itc-filter'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { AlignLeftOutlined, FilterOutlined, FilterTwoTone } from '@ant-design/icons'
import ItcCard from './components/itc-card'
import PageFallback from '../../../../components/page-fallback'


const { Content } = Layout
const {Title, Paragraph} = Typography
const {Search} = Input
const {Panel} = Collapse


const DiscoverItc = () => {
    const history = useHistory()
    const location = useLocation();
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const workCategories  = useSelector(state => state.workCategoriesGlobal)
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const [itcListings, setItcListings] = useState([])
    const [loadingItc, setLoadingItc] = useState(false)
    const [filteredItc, setFilteredItc] = useState([])
    const [sortValue, setSortValue] = useState('newest')
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [openSortModal, setOpenSortModal] = useState(false)
    const [currentTab, setCurrentTab] = useState('')
    const [appliedItcs, setAppliedItcs] = useState([])
    const [matchedItc, setMatchedItc] = useState([])
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [specializationsData, setSpecializationsData] = useState([])
    const [techCategories, setTechCategories] = useState([])
    const [loadingMemberProfiles, setLoadingMemberProfiles] = useState(false)
    const [memberItcProfiles, setMemberItcProfiles] = useState([])
    const [invitedItcs, setInvitedItcs] = useState([])
    const [inactiveItcs, setInactiveItcs] = useState([])
    const [isRefreshed, setIsRefreshed] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    
    const { windowWidth } = useWindowSize()

    const activeUser = tech || user

    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const searchQueryParam = queryParams.get('query');
    const categoryParam = queryParams.get('category');
    const typeParam = queryParams.get('type');
    const membershipFeeParam = queryParams.get('membershipFee');
    const itcLocationParam = queryParams.get('location');

    // State variables to hold filter values
    const [query, setQuery] = useState(searchQueryParam || '')
    const [category, setCategory] = useState(categoryParam || '');
    const [type, setType] = useState(typeParam || '');
    const [membershipFee, setMembershipFee] = useState(membershipFeeParam || '');
    const [itcLocation, setItcLocation] = useState(itcLocationParam || '');

    let filterTags = [];

    // Iterate through all key-value pairs and push them into the filter tags array, also handle close tag
    for (let [key, value] of queryParams.entries()) {
        let closeFilter = () => {}
        if(key === "query"){
            key = "Query"
            value = capitalizeFirstLetter(value) 
            closeFilter = () => setQuery('')
        }
        if(key==="category"){
            key = "Category"
            value = value
            closeFilter = () => setCategory('')
        }
        if(key==="type"){
            key = "Type"
            value = value
            closeFilter = () => setType('')
        }
        if(key==="membershipFee"){
            key = "Membership Fee"
            value = `${value}`
            closeFilter = () => setMembershipFee('')
        }
        if(key==="itcLocation"){
            key = "Location"
            value = value
            closeFilter = () => setItcLocation('')
        }
        filterTags.push({ key, value, closeFilter });
    }


    // Update the URL with the filter query parameters when the state changes
    useEffect(() => {
        const newSearchParams = new URLSearchParams();
        if(query) {
            newSearchParams.set('query', query);
        }
        if (category) {
            newSearchParams.set('category', category);
        }
        if (type) {
            newSearchParams.set('type', type);
        }
        if (membershipFee) {
            newSearchParams.set('membershipFee', membershipFee);
        }
        if (itcLocation) {
            newSearchParams.set('itcLocation', itcLocation);
        }
        
        // Construct the query string manually using the "&" separator
        const queryString = newSearchParams.toString();
        const newUrl = `${location.pathname}${queryString ? `?${queryString}` : ''}`;
        
        // Replace the URL without causing a full page reload
        history.replace(newUrl);
    }, [query, category, type, history, membershipFee, itcLocation, location.pathname]);

    const handleItcFilter = () => {

        const filteredResults = itcListings?.filter((item) => {

            // Apply filtering logic 
            const matchesQuery = !query || item?.Company_Name?.toLowerCase().includes(query.toLowerCase());
            const matchesCategory = !category || item.Categories.some(ele => category.toLowerCase() === ele.Category.Title.toLowerCase())
            const matchesType = !type || (type.toLowerCase() === 'project' ? item?.isProject === "1" : item?.isProject !== "1")

            const matchedItcLocation = !itcLocation || item?.Location?.City?.toLowerCase().includes(itcLocation.toLowerCase());
            let matchedMembershipFee
            if (membershipFee === "Free") {
                matchedMembershipFee = (Number(item?.Membership_Fee)) == 0
            }
            if(membershipFee === "$1 - $100"){
                matchedMembershipFee = (Number(item?.Membership_Fee)) >= 1 && (Number(item?.Membership_Fee)) <= 100
            }else if(membershipFee === "$100 - $500"){
                matchedMembershipFee = (Number(item?.Membership_Fee)) >= 100 && (Number(item?.Membership_Fee)) <= 500
            }else if(membershipFee === "$500 - $1k"){
                matchedMembershipFee = (Number(item?.Membership_Fee)) >= 500 && (Number(item?.Membership_Fee)) <= 1000
            }else if(membershipFee === "$1k - $5k"){
                matchedMembershipFee = (Number(item?.Membership_Fee)) >= 1000 && (Number(item?.Membership_Fee)) <= 5000
            }else if(membershipFee === "$5k+"){
                matchedMembershipFee = (Number(item?.Membership_Fee)) >= 5000
            }else{
                matchedMembershipFee = true
            }

            return matchesQuery && matchesCategory && matchesType && matchedMembershipFee && matchedItcLocation;
        });
        setFilteredItc(filteredResults);
    }

    function handleClearFilter() {
        setQuery('')
        setCategory('')
        setType('')
        setMembershipFee('')
        setItcLocation('')
    }

    const handleItcSort = (value) => {
        let sortedData = [...filteredItc]
        if(value === "newest"){
            sortedData.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))
        }
        else if(value === "high-low"){
           sortedData.sort((a, b) => {
                return parseInt(b?.Membership_Fee) - parseInt(a?.Membership_Fee) 
            })
        }else if(value === "low-high"){
            sortedData.sort((a, b) => {
                return parseInt(a?.Membership_Fee) - parseInt(b?.Membership_Fee)
            })
        }
        setSortValue(value)
        setFilteredItc(sortedData)
    }


    const fetchAllItc = () => {
        setLoadingItc(true)
        ApiBaseHelper.get({
            url: `itc/discover?limit=1000`, 
            headers: {
                Authorization: 'Bearer ' + activeUser?.token
            }
        }).then((res) => {
            const itcData = res?.data?.data?.data
            if(tech){
                setItcListings(itcData?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))?.filter((item) => item?.Active === "1" && item?.Owner_Contact !== tech?.id && memberItcProfiles.every((profile) => profile?.id !== item?.id)))
            }else{
                setItcListings(itcData?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))?.filter((item) => item?.Active === "1"))
            }
            setLoadingItc(false)
        }).catch((error) => {
            console.error(error)
            setLoadingItc(false)
        })       
    }

     // Get all ITSC profiles that the technician has applied to
     const getAppliedItcs = () => {
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Applied`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setAppliedItcs(res?.data?.data?.filter((item) => item !== null))
        }).catch((error) => {
            console.error(error)
        })
    }

    // Get all ITSC profiles that the technician has an active membership with
    const getActiveItcs = () => {
        setLoadingMemberProfiles(true)
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Active`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setMemberItcProfiles(res?.data?.data?.filter((item) => item !== null && item.Owner_Contact !== tech?.id).sort((a, b) => new Date(b?.CreatedDate) - new Date(a?.CreatedDate)))
            setLoadingMemberProfiles(false)
        }).catch((error) => {
            console.error(error)
            setLoadingMemberProfiles(false)
        })
    }

    // Get all ITSC profiles that the technician has been invited to
    const getInvitedItcs = () => {
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Invited`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setInvitedItcs(res?.data?.data?.filter((item) => item !== null))
        }).catch((error) => {
            console.error(error)
        })
    }

     // Get all ITSC profiles that the technician has been invited to
     const getInactiveItcs = () => {
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Inactive`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setInactiveItcs(res?.data?.data?.filter((item) => item !== null))
        }).catch((error) => {
            console.error(error)
        })
    }

    const fetchSpecializations = () => {
        setLoadingCategories(true)
        ApiBaseHelper.get({
            url: `work-categories/specialization`, headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setSpecializationsData(res.data.data.sort((a, b) => b?.Active.localeCompare(a?.Active)))
            setLoadingCategories(false)
        }).catch((error) => {
            console.error(error)
            setLoadingCategories(false)
        })       
    }

    const refreshItc = () => {
        setIsRefreshed(true)
        fetchAllItc()
        getAppliedItcs()
        getActiveItcs()
        getInvitedItcs()
        getInactiveItcs()
        setCurrentTab(currentTab)
        setCurrentPage(currentPage)
        setLoadingItc(false)
        setLoadingMemberProfiles(false)
        setLoadingItc(false)
    }
  
    useEffect(() => {
        if(tech){
            getAppliedItcs()
            fetchSpecializations()
            getActiveItcs()
            getInvitedItcs()
            getInactiveItcs()
        }
    }, [])

    useEffect(() => {
        fetchAllItc()
    }, [memberItcProfiles])

    useEffect(() => {
        const categoryTitles = specializationsData.map(obj => obj.Category.Title);
        setTechCategories(categoryTitles)
    }, [specializationsData])

    useEffect(() => {
        if(tech && !isRefreshed){
            setCurrentTab("best_match")
            const matchedData = itcListings?.filter(item =>
                item.Categories.some(category => techCategories?.includes(category.Category.Title))
            );
            setMatchedItc(matchedData)
        }
    }, [itcListings])

    useEffect(() => {
        handleItcFilter()    
        window.scrollTo(0, 0)
    }, [ query, category, type, membershipFee, itcListings, itcLocation])

    useEffect(() => {
        setCurrentTab("all")
    }, [ query, category, type,  membershipFee, itcLocation])

    if( !isRefreshed && (loadingMemberProfiles || loadingItc)){
        return <PageFallback />
    }

    return (
        <>
            <MetaTags>
                <title>Discover ITSC - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn discover-itc-ctn">
                            <Row gutter={65}>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                    <aside className="discover-itc-sidebar">
                                        {
                                            windowWidth > 991 &&
                                            <>
                                                <Title level={4} style={{marginBottom:24}}>Filter By</Title>
                                                <ItcFilter
                                                    workCategories={workCategories}
                                                    queryParams={queryParams}
                                                    query={query} setQuery={setQuery}
                                                    category={category} setCategory={setCategory}
                                                    type={type} setType={setType}
                                                    membershipFee={membershipFee} setMembershipFee={setMembershipFee}
                                                    itcLocation={itcLocation} setItcLocation={setItcLocation}
                                                />
                                            </>
                                        }
                                    </aside>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                    <main className="discover-itc-content">
                                        <div className="discover-itc-search">
                                            <Search
                                                placeholder="Search for ITSCs"
                                                allowClear
                                                enterButton={windowWidth > 991 ? "Search" : false}
                                                size="large"
                                                // value={query}
                                                // onChange={(e) => setQuery(e.target.value)}
                                                onSearch={(value) => setQuery(value)}
                                            />
                                        </div>
                                        {/* <div className="search-num-ctn">
                                           { 
                                                loadingItc ? <Paragraph style={{margin:0, fontWeight:600, fontSize:14}}>Loading ITSCs...</Paragraph> :
                                                <Paragraph style={{margin:0, fontWeight:600, fontSize:14}}>{filteredItc?.length} ITSCs found</Paragraph>
                                           }
                                            {
                                                windowWidth > 991 &&
                                                <div className="sort-ctn">
                                                    <Paragraph style={{margin:0, fontWeight:600, fontSize:14}}>Sort by:</Paragraph>
                                                    <Select 
                                                        defaultValue="newest" style={{ width: 164 }} size='large'
                                                        onChange={(value) => handleItcSort(value)}
                                                        value={sortValue}
                                                    >
                                                        <Select.Option value="newest">Newest</Select.Option>
                                                        <Select.Option value="high-low">Fee (High to Low)</Select.Option>
                                                        <Select.Option value="low-high">Fee (Low to High)</Select.Option>
                                                    </Select>
                                                </div>
                                            }   
                                        </div> */}
                                        {
                                            windowWidth < 992 &&
                                            <div className='mobile-filter-cta'>
                                                <Button 
                                                    type='secondary' 
                                                    className='filter-btn' 
                                                    icon={<FilterTwoTone  twoToneColor={"#F04A22"}/>}
                                                    onClick={() => setOpenFilterModal(true)}
                                                    >
                                                    Filter
                                                </Button>
                                                <Button 
                                                    type='secondary' 
                                                    className='filter-btn' 
                                                    icon={<AlignLeftOutlined style={{color:"#F04A22"}}/>}
                                                    onClick={() => setOpenSortModal(true)}
                                                    >Sort</Button>
                                            </div>
                                        }
                                        {
                                            filterTags?.length > 0 &&
                                            <div className='filter-tags-ctn'>
                                                {
                                                    filterTags.map((item, index) => (
                                                    <Tag  
                                                        style={{backgroundColor:"rgb(254, 237, 233)", color:SECONDARY_COLOR, fontSize:12, border:"none"}} className='custom-ant-tag' 
                                                        key={item?.key}
                                                        closable
                                                        onClose={item.closeFilter}
                                                    >
                                                        {item?.key}: {item?.value}
                                                    </Tag>
                                                    ))
                                                }
                                                <Button
                                                    type="text"
                                                    className={"font-weight-500 text-orange mb-2"}
                                                    onClick={handleClearFilter}
                                                >  
                                                    Clear filter{filterTags.length > 1 ? 's' : ''}  
                                                </Button>
                                            </div>
                                        }
                                        <div className="itsc-listings-ctn">
                                            <Tabs
                                                defaultActiveKey='best_match'
                                                activeKey={currentTab}
                                                onTabClick={(key) => setCurrentTab(key)}
                                                tabBarExtraContent={
                                                    windowWidth > 991 &&
                                                    <div className="sort-ctn">
                                                        <Paragraph style={{margin:0, fontWeight:600, fontSize:14}}>Sort by:</Paragraph>
                                                        <Select 
                                                            defaultValue="newest" style={{ width: 164 }} size='large'
                                                            onChange={(value) => handleItcSort(value)}
                                                            value={sortValue}
                                                        >
                                                            <Select.Option value="newest">Newest</Select.Option>
                                                            {/* <Select.Option value="high-low">Fee (High to Low)</Select.Option>
                                                            <Select.Option value="low-high">Fee (Low to High)</Select.Option> */}
                                                        </Select>
                                                    </div>
                                                }   
                                            >
                                               { 
                                                    tech &&
                                                    <Tabs.TabPane tab={`Best Match (${matchedItc?.length})`} key="best_match">
                                                        <Paragraph className='best-match-info'>"{matchedItc?.length} ITSC{matchedItc?.length > 1 ? 's' : ''} found" based on your skills & matching preferences. Change preference <Link to={ROUTES.MANAGE_SKILLS}>here. </Link></Paragraph>
                                                        <CustomRenderEmpty
                                                            description="No ITSCs found"
                                                        >
                                                            <List
                                                                className='itc-card-list'
                                                                dataSource={matchedItc}
                                                                pagination={{
                                                                    total:matchedItc?.length,
                                                                    defaultPageSize:8,
                                                                    onChange: page => {
                                                                        window.scrollTo({
                                                                            top: 0,
                                                                            behavior: 'smooth'
                                                                        })
                                                                        setCurrentPage(page)
                                                                    },
                                                                    current: currentPage
                                                                }}
                                                                loading={loadingItc}
                                                                renderItem={
                                                                    item => {
                                                                        return(
                                                                        <List.Item>
                                                                            <ItcCard 
                                                                                cardDetails={item}
                                                                                appliedItcs={appliedItcs}
                                                                                invitedItcs={invitedItcs}
                                                                                inactiveItcs={inactiveItcs}
                                                                                refreshItc={refreshItc}
                                                                            />
                                                                        </List.Item>
                                                                    )}
                                                                }
                                                            /> 
                                                        </CustomRenderEmpty>
                                                    </Tabs.TabPane>
                                                }
                                                <Tabs.TabPane tab={`All (${filteredItc?.length})`}  key="all">
                                                    <CustomRenderEmpty
                                                        description="No ITSCs found"
                                                    >
                                                        <List
                                                            className='itc-card-list'
                                                            dataSource={filteredItc}
                                                            pagination={{
                                                                total:filteredItc?.length,
                                                                defaultPageSize:8,
                                                                onChange: page => {
                                                                    window.scrollTo({
                                                                        top: 0,
                                                                        behavior: 'smooth'
                                                                    })
                                                                    setCurrentPage(page)
                                                                },
                                                                current: currentPage
                                                            }}
                                                            loading={loadingItc}
                                                            renderItem={
                                                                item => {
                                                                    return(
                                                                    <List.Item>
                                                                        <ItcCard 
                                                                            cardDetails={item}
                                                                            appliedItcs={appliedItcs}
                                                                            invitedItcs={invitedItcs}
                                                                            inactiveItcs={inactiveItcs}
                                                                            refreshItc={refreshItc}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            }
                                                        />
                                                    </CustomRenderEmpty>
                                                </Tabs.TabPane>
                                            </Tabs>
                                        </div>        
                                    </main>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Content>
            <Modal
                title="Filter by"
                visible={openFilterModal}
                onOk={() => setOpenFilterModal(false)}
                onCancel={() => {
                    setOpenFilterModal(false)
                    handleClearFilter()
                }}
                className='filter-modal'
            >
                <ItcFilter
                    workCategories={workCategories}
                    queryParams={queryParams}
                    query={query} setQuery={setQuery}
                    category={category} setCategory={setCategory}
                    type={type} setType={setType}
                    membershipFee={membershipFee} setMembershipFee={setMembershipFee}
                />
            </Modal>
            <Modal
                title="Sort ITSCs by"
                visible={openSortModal}
                onOk={() => setOpenSortModal(false)}
                onCancel={() => {
                    setOpenSortModal(false)
                    handleItcSort("newest")
                }}
                footer={null}
                className='filter-modal'
            >
                <div className='sort-modal-ctn'>
                    <Radio.Group onChange={(e) => setSortValue(e.target.value)} value={sortValue}>
                        <Space direction="vertical">
                            <div className='sort-modal-card'>
                                <Radio value={"newest"}>Newest</Radio>
                            </div>
                            {/* <div className='sort-modal-card'>
                                <Radio value={"high-low"}>Price (High to Low)</Radio>
                            </div>
                            <div className='sort-modal-card'>
                                <Radio value={"low-high"}>Price (Low to High)</Radio>
                            </div> */}
                        </Space>
                    </Radio.Group>
                  
                </div>
                <Button type='primary' 
                className='apply-sort-btn'
                onClick={() => {
                    setOpenSortModal(false)
                    handleItcSort(sortValue)
                }}
                >Apply</Button>
            </Modal>
        </>
    )
}

export default DiscoverItc