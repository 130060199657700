import React, { useEffect } from 'react'
import { Avatar, Button, Col, Drawer, Form, Input, Modal, Popover, Rate, Row, Space, Tag, Typography, notification } from 'antd'
import { CheckOutlined, EnvironmentOutlined, ExportOutlined, EyeOutlined, LoadingOutlined, MessageOutlined, SafetyCertificateFilled, SelectOutlined, WalletOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import ROUTES from '../../../../../../utils/routes'
import { addSubdomainToBaseUrl, capitalizeFirstLetter, convertIfLight, encryptString, nameFormat, stripHtml} from '../../../../../../utils/helper'
import useWindowSize from '../../../../../../hooks/useWindowSize'
import { useSelector } from 'react-redux'
import ActionIcon from '../../../../../../assets/images/action-icon.svg'	
import '../assets/css/itc-card.scss'
import ChatIcon from '../assets/images/chat-itc.svg'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import { FirebaseHelper, FirestoreHelper } from '../../../../../../utils/firebase-helper'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import { RtmRepository } from '../../real-time-messaging/repository/rtm-repository'
import { decode } from 'html-entities'
import NewMessageModal from '../../../../tech/itc/landing-page/components/new-message-modal'
import { set } from 'date-fns'
import { ref } from 'firebase/database'
import ProjectTag from '../../../../tech/itc/landing-page/components/project-tag'


const { Title, Paragraph, Text } = Typography
const moment = extendMoment(Moment);
 
const ItcCard = ({cardDetails, appliedItcs, invitedItcs, inactiveItcs, refreshItc}) => {
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const history = useHistory()
    const location = useLocation()
    const { windowWidth } = useWindowSize()
    const [loadingConversationId, setLoadingConversationId] = useState(false)
    const [openNoteModal, setOpenNoteModal] = useState(false)
    const [itcDetails, setItcDetails] = useState({})
    const [sendingRequest, setSendingRequest] = useState(false)
    const [activeConversationId, setActiveConversationId] = useState(null)
    const [ownerContactId, setOwnerContactId] = useState(null)
    const [loadingOwnerDetails, setLoadingOwnerDetails] = useState(false)
    const [messageModalVisible, setMessageModalVisible] = useState(false)
    const [openInviteModal, setOpenInviteModal] = useState(false)
    const [inactiveMemberDetails, setInactiveMemberDetails] = useState({})
    const [loadingInactiveMemberDetails, setLoadingInactiveMemberDetails] = useState(false)
    
    const activeUser = user || tech

    const rtmRepository = new  RtmRepository(activeUser.token)
    
    // Function to get and set active conversation id for the user
    const getAndSetActiveConversationId = async () => {
        await FirebaseHelper.login();
        let conversationExists = await FirestoreHelper.getConversationBetweenUsers({
            user_1: activeUser['id'],
            user_2: ownerContactId,
        });
        let conversationId = conversationExists.length > 0 ? conversationExists[0].id : null;
        setActiveConversationId(conversationId)
    }

    // Fetch conversation id and go to tech chat.
    const goToChat = async (ownerId) => {
        setLoadingConversationId(true)
        await FirebaseHelper.login();
        let conversationExists = await FirestoreHelper.getConversationBetweenUsers({
            user_1: activeUser['id'],
            user_2: ownerId,
        });
        let conversationId = conversationExists.length > 0 ? conversationExists[0].id : null;
        setActiveConversationId(conversationId)
        setLoadingConversationId(false)
        if(!conversationId){
            setMessageModalVisible(true)
        }else{
            history.push(`${ROUTES.INBOX}?id=${conversationId}`)
        }
    }

    const sendMessage = async (message) => {
        const baseUrl = window.location.origin
        const activeUser = user || tech
        await FirebaseHelper.login();
        await FirestoreHelper.sendMessage({
            conversation_id: activeConversationId,
            message:  encryptString(
                `Request to join ${itcDetails?.Company_Name}: <br><br>
                ${message} <br>
                Click here to view pending requests: <a href="${`${`${baseUrl}${ROUTES.ITC_TECH_TEAM.replace(':id', itcDetails?.id)}?tab=requests`}`}">${`${baseUrl}${ROUTES.ITC_TECH_TEAM.replace(':id', itcDetails?.id)}?tab=requests`}</a> 
                `
            ),
            user_id: activeUser.id
        });
        await rtmRepository?.sendMessage({
            conversationId: activeConversationId,
            message,
            createdAt: moment().utc().toISOString()
        })
    }

    // get inactive ITC member details
    const getInactiveItcMemberDetails = (itcCardDetails) => {
        setLoadingInactiveMemberDetails(true)
        ApiBaseHelper.get({
            url: `itc/member/status/${itcCardDetails?.id}/${tech.id}?status=Inactive`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setInactiveMemberDetails(res?.data?.data[0])
            setLoadingInactiveMemberDetails(false)
            setOpenNoteModal(true)
        }).catch((error) => {
            console.error(error)
            setLoadingInactiveMemberDetails(false)
        })       
    }


    // Send join request to ITSC
    const sendJoinRequest = (values) => {
        setSendingRequest(true)
        if(inactiveItcs.find((itsc) => itsc.id === itcDetails?.id)){
            ApiBaseHelper.put({
                url: `itc/member/${inactiveMemberDetails?.id}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload:{
                    Status: "Applied",
                }
                
            }).then((res) => {
                setSendingRequest(false)
                setOpenNoteModal(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Request sent successfully'
                })
                refreshItc()
                if(values.message){
                    sendMessage(values?.message)
                }
            }).catch((err) => {
                setSendingRequest(false)
                notification.error({
                    message: 'TecMe',
                    description: 'Error! Unable to send request'
                })
                console.log(err)
            })
        }else{
            ApiBaseHelper.post({
                url: `itc/member`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload:{
                    Member_Contact: tech?.id,
                    Member_Org: tech?.orgId,
                    Status: "Applied",
                    // Work_Category: member?.work_categories.join("|"),
                    ITC: itcDetails?.id,
                }
                
            }).then((res) => {
                setSendingRequest(false)
                setOpenNoteModal(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Request sent successfully'
                })
                refreshItc()
                if(values.message){
                    sendMessage(values?.message)
                }
            }).catch((err) => {
                setSendingRequest(false)
                notification.error({
                    message: 'TecMe',
                    description: 'Error! Unable to send request'
                })
                console.log(err)
            })
        }
    }

    // Fetch Owner Details and go to contract proposal page
    const goToHirePage = (itcData) => {
        setLoadingOwnerDetails(true)
        ApiBaseHelper.get({
            url: `web/search-tech?contactId=${itcData?.Owner_Contact}`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            const ownerDetails  = res?.data?.data?.Data
            history.push({
                pathname: `${ROUTES.CREATE_SEND_CONTRACT.replace(":profileUrl", ownerDetails?.Profile_URL)}`,
                state:  {
                    techProfile: ownerDetails,
                    isHipaaService: ownerDetails?.Tags === "HIPAA",
                    // specialization:workCategories?.find((item) => item?.id === jobDetails?.Category),
                    hire: false,
                    itcDetails: itcData,
                },
            })
            setLoadingOwnerDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingOwnerDetails(false)
        })       
    }

    // Get unique skills (by removing duplicates)
    const uniqueSkills = Array.from(new Set(
        cardDetails?.Categories?.reduce((acc, cat) => {
          const skills = cat?.Skills.split('|').map(skill => skill.toLowerCase()); // Normalize to lowercase
          return acc.concat(skills);
        }, [])
      )).slice(0, 20);

    const itcPopoverContent = (item) => {
        return(
            <div className="itc-profile-popover-content">   
               { 
                ((!appliedItcs.find((itsc) => itsc.id === item?.id) && tech)) &&
               <Space className='popover-action-item' 
                    align='center' 
                    onClick={(e) =>{ 
                        e.stopPropagation()
                        setItcDetails(item)
                        if(invitedItcs.find((itsc) => itsc.id === item?.id)){
                            setOpenInviteModal(true)
                            return
                        }
                        if(inactiveItcs.find((itsc) => itsc.id === item?.id)){
                            getInactiveItcMemberDetails(item)
                            return
                        }
                        setOpenNoteModal(true)
                    }}>
                        {
                            loadingInactiveMemberDetails ? 
                            <LoadingOutlined className='popover-action-icon'/> :
                            <ExportOutlined className='popover-action-icon'/>
                        }
                        <Paragraph style={{marginBottom:0}}>Send Request</Paragraph>
                </Space> 
                }
                {
                    user &&
                    <Space className='popover-action-item' 
                        align='center' 
                        onClick={(e) =>{ 
                            e.stopPropagation()
                            goToHirePage(item)
                        }}>
                            { 
                                loadingOwnerDetails ?
                                <LoadingOutlined className='popover-action-icon'/> :
                                <SelectOutlined className='popover-action-icon'/>
                            }
                            <Paragraph style={{marginBottom:0}}>Hire Company</Paragraph>
                    </Space>
                }
                <Space className='popover-action-item' 
                    align='center' 
                    onClick={(e) =>{ 
                        history?.push(ROUTES.ITC_HOME.replace(':subdomain', cardDetails?.Subdomain))
                        e.stopPropagation()
                    }}>
                    <EyeOutlined className='popover-action-icon' />
                    <Paragraph style={{marginBottom:0}}>View ITSC webpage</Paragraph> 
                </Space>
            </div>
        )
    }
    
    useEffect(() => {
        if(ownerContactId){
            getAndSetActiveConversationId()
        }
    }, [ownerContactId])

   
    return (
        <>
            <div key={cardDetails.id} className='discover-itc-card'
             onClick={() => {}}>
                <div className='discover-itc-card-heading'>
                    <Space align='top'>
                        <Avatar 
                            src={cardDetails?.Brand} 
                            size={windowWidth > 991 ? 45 : 30}
                            shape="square"
                            style={{
                                backgroundColor: convertIfLight(`#${cardDetails?.Brand_Color}`)
                            }}
                        >
                            {cardDetails?.Company_Name?.charAt(0).toUpperCase()}
                        </Avatar>
                        <div>
                            <Space wrap>
                                <Title level={5} style={{margin:0, fontSize:16}}>{capitalizeFirstLetter(cardDetails?.Company_Name)}</Title>
                                { windowWidth > 991 && <span className='dot'>•</span>}
                                <Text style={{fontSize:12, fontWeight:500, color:"rgba(0, 0, 0, 0.65)", flexShrink:0}}>{Number(cardDetails?.Years_Experience)} year{Number(cardDetails?.Years_Experience) > 1 ? 's' : ''}</Text>
                                { windowWidth > 991 && <span className='dot'>•</span>}
                                <a href={addSubdomainToBaseUrl(cardDetails?.Subdomain)} target="_blank" rel="noopener noreferrer">
                                    {
                                        addSubdomainToBaseUrl(cardDetails?.Subdomain).includes('http') &&
                                        addSubdomainToBaseUrl(cardDetails?.Subdomain).replace('https://', 'www.').replace('http://', 'www.')
                                    }
                                </a>
                            </Space>
                            <div className='discover-itc-card-subheading'>
                                <Paragraph><span>Membership Fee: </span>{Number(cardDetails?.Membership_Fee) > 0 ? `$${Number(cardDetails?.Membership_Fee)}` : "Free"}</Paragraph>
                                {
                                    cardDetails?.isProject === "1" &&
                                    <ProjectTag />
                                }
                            </div>
                        </div>
                    </Space>
                    <Space size={13} align='center'>
                        {
                            loadingConversationId ?
                            <LoadingOutlined  style={{fontSize:26}}/> :
                            <img src={ChatIcon} alt="action icon" onClick={() => {
                                setOwnerContactId(cardDetails?.Owner_Contact)
                                setItcDetails(cardDetails)
                                goToChat(cardDetails?.Owner_Contact)
                            }}/>  
                        }
                        <Popover content={itcPopoverContent(cardDetails)}  trigger="click" placement='bottomLeft' overlayClassName="itc-profile-popover" arrowPointAtCenter>
                            <img src={ActionIcon} alt="action icon" onClick={
                            (e) => {
                                e.stopPropagation()
                                setOwnerContactId(cardDetails?.Owner_Contact)
                            }}/>  
                        </Popover>
                    </Space>
                </div>
                <Paragraph ellipsis={{rows:3}} style={{fontSize:14}}>{capitalizeFirstLetter(decode(stripHtml(cardDetails.Description)))}</Paragraph>
                <div className="discover-itc-skills">
                    {
                        uniqueSkills?.map((skill, index) => {
                            return (
                                <Tag key={index} className="custom-ant-tag">{skill}</Tag>
                            )
                        })
                    }
                </div>
                <Space wrap>
                    { (cardDetails?.Location?.State || cardDetails?.Location?.City) &&
                    <Space size={4}>
                        <EnvironmentOutlined style={{position:"relative", top:2}}/>
                        <Paragraph style={{margin:0, fontSize:12, fontWeight:500}}>{capitalizeFirstLetter(cardDetails?.Location?.City)}{cardDetails?.Location?.State ? `, ${cardDetails?.Location?.State}` : ""}</Paragraph>
                    </Space>
                    }
                    { (cardDetails?.Location?.State || cardDetails?.Location?.City) && <span className='dot'>•</span>}
                    <Paragraph  style={{margin:0, fontSize:12, fontWeight:500}}>{cardDetails?.Member_Count} {`Technician${cardDetails?.Member_Count > 1 ? 's' : ''}`}</Paragraph>
                    {
                        ((!!appliedItcs.find((itsc) => itsc.id === cardDetails?.id) && tech)) ? 
                        <>
                           <span className='dot'>•</span>
                            <Space>
                                <CheckOutlined className='safety-icon' style={{color:"#36A7F9D9"}}/>
                                <Paragraph style={{margin:0, fontSize:12, fontWeight:500}}>Applied</Paragraph>
                            </Space>
                        </> :
                        null
                    }
                </Space>
                <Modal  
                    title={
                        <div>
                            <Title level={5} style={{margin:0}}>Send a request to join {itcDetails?.Company_Name}</Title>
                            <Paragraph style={{margin:0, fontSize:12, color:"rgba(0, 0, 0, 0.65)"}}>This request will be sent as a chat to the ITSC owner</Paragraph>
                        </div>
                    } 
                    visible={openNoteModal} 
                    okText="Send Request"
                    onCancel={() => setOpenNoteModal(false)} 
                    okButtonProps={{htmlType:"submit", loading:sendingRequest, form:'send-request-form'}}
			        cancelButtonProps={{type:"secondary"}}
                    width={windowWidth > 991 ? 600 : 300}
                    className="itc-request-modal"
                >
                        <Form
                            layout='vertical'
                            onFinish={sendJoinRequest}
                            name='send-request-form'
                        >
                            <Form.Item
                                label={<Paragraph style={{fontWeight:500}}>Write a description of your aspirations to join the ITSC (optional)</Paragraph>}
                                name="message"
                            >
                                <Input.TextArea
                                    style={{width:"100%", height:100, padding:10, borderRadius:5, border:"1px solid #D9D9D9"}}
                                    placeholder="Type your message here"
                                />
                            </Form.Item>
                        </Form>
                </Modal>
                <Modal
                    visible={openInviteModal}
                    onCancel={() => setOpenInviteModal(false)}
                    footer={null}
                >
                    <Title level={3} className='font-weight-500' style={{marginTop: '-0.8rem'}}>You can't send a request.</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                        You have a pending invite for this ITSC already. Click on the button below to view the invite details.
                    </Paragraph>
                    <Space align='center' className='w-100 justify-content-end'>
                        <Button 
                            type='text' 
                            onClick={() => setOpenInviteModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type='primary' 
                            onClick={() =>  history?.push(ROUTES.ITC_INVITE_DETAILS.replace(':id', itcDetails.id))}
                        >
                            View Invite
                        </Button>
                    </Space>
                </Modal>
            </div>
           { 
            messageModalVisible &&
            <NewMessageModal
                    messageModalVisible={messageModalVisible}
                    setMessageModalVisible={setMessageModalVisible}
                    itcContent={itcDetails}
                    activeConversationId={activeConversationId}
                />
            }
        </>
    )
}

export default ItcCard