import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Layout, Row, Col, Typography, Form, Input, Button, Select, notification, Spin, Modal } from 'antd';
import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";
import ROUTES from '../../../../../../utils/routes';
import { PaymentRepository } from '../payment-repository/payment-repository';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import InputMask from 'react-input-mask';
import {META_DESCRIPTION} from "../../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import PageHeaderWrapper from '../../../../../components/page-header-breadcrumbs';
import "../assets/css/styles.css"
import { useState } from 'react';
import { useEffect } from 'react';
import { canReadPageOnly, getCreditCardInfo } from '../../../../../../utils/helper';
import { values } from 'pdfjs-dist';
const antIcon = <LoadingOutlined style={{ fontSize: 22, color: "black" }} spin />;
const { Paragraph, Title } = Typography
const { Content } = Layout
const { TextArea } = Input
const {useForm} = Form

const AddEditPaymentModal = (props) => {

    const [cvc, setCvc] = useState('')
    const [expiry, setExpiry] = useState('')
    const [number, setNumber] = useState('')
    const [isFormChange, setIsFormChange] = useState(false)
    const [loading, setLoading] = useState(false)
    const [expiryError, setExpiryError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [form] = useForm();

    const paymentRepository =  new PaymentRepository(props.user?.token)

    const openNotification = (value) => {
        notification.open({
            message: value.message,
            description: value.description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const info = (message) => {
        Modal.info({
            title: 'TecMe',
            content: (
                <div>
                    <Title></Title>
                    <Paragraph>{message}</Paragraph>

                </div>
            ),
            onOk() { },
        });
    }

    const clearForm = () => {
        form.resetFields();
        setNumber('')
        setCvc('')
        setExpiry('')
    }

    useEffect(() => {
        if(formSubmitted){
            form.resetFields();
            setExpiry('')
        }
    },[formSubmitted])

    useEffect(() => {
        if(props.option === "edit"){
            form.setFieldsValue({
                expiry:props.expiryDate,
                number: props.paymentDetails?.card?.brand.toLowerCase() === "amex" ? `***********${props.paymentDetails?.card?.last4}` : `************${props.paymentDetails?.card?.last4}`,
                cvc: props.paymentDetails?.card?.brand.toLowerCase() === "amex" ? '****' : '***'
            })
        }
    }, [props.option])

    return (
        <Fragment>
            <MetaTags>
                <title >{`${props.option === "add" ? "Add" : "Edit"} Payment Method` } | TecMe </title>
                <meta name="description" content={META_DESCRIPTION} />
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Modal 
                title={`${props.option === "add" ? "Add" : "Edit"} Payment Method` }
                width={800}
                open={props.open} 
                onOk={() => {
                    clearForm()
                    props.onOk()
                }} 
                footer={null}
                onCancel={() => {
                    clearForm()
                    props.onCancel()
                }}>
                      <div className="page">
                    <div>   
                        <Row gutter={[15, 15]} align="middle" className='payment-method-row' style={{margin:"0 auto"}}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div style={{ margin: 0 }}>
                                    <Cards
                                        cvc={cvc}
                                        expiry={props.option === "edit" ? props.expiryDate : expiry.replace(/^F0+/i, '')}
                                        preview={true}
                                        name=" "
                                        issuer={props.option === "edit" && props.paymentDetails?.card?.brand}
                                        number={props.option === "edit" ?  `***********${props.paymentDetails?.card?.brand.toLowerCase() === "amex" ? "" : "*"} ${props.paymentDetails?.card?.last4}` : number}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Title level={2}>Payment Details</Title>
                                <Form
                                style={{ width: '100%', margin: '1rem 0' }}
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    if (expiryError == true) {
                                        return
                                    }
                                    let expiry = values.expiry.split("/")
                                    setIsFormChange(false)
                                    setLoading(true)
                                    const data = new FormData();
                                    if(props.option === "add") {
                                        data.append("number", values.number)
                                        data.append("exp_month", expiry[0])
                                        data.append("exp_year", expiry[1])
                                        data.append("cvc", values.cvc)
                                        paymentRepository.addPayment(data)
                                            .then(res => {
                                                setLoading(false)
                                                openNotification({ message: 'Payment Method Notification', description: 'Payment Method Successfully Added' })
                                                props.history.push(ROUTES.PAYMENT)
                                                setNumber('')
                                                setCvc('')
                                                setExpiry('')
                                                props.fetchPaymentMethods()
                                                setFormSubmitted(true)

                                            })
                                            .catch(err => {
                                                console.log(err?.response?.data?.message)
                                                setLoading(false)
                                                info(err?.response?.data?.message)
                                            })
                                    }
                                    if(props.option === "edit"){
                                        data.append("exp_month", String(expiry[0]))
                                        data.append("exp_year", String(expiry[1]))
                                        paymentRepository.editPayment(props.paymentDetails?.id, data)
                                        .then(res => {
                                            openNotification({
                                                message: 'Payment Method Update Notification',
                                                description: 'Payment Method Successfully Updated'
                                            })
                                            props.history.push(ROUTES.PAYMENT)
                                            props.fetchPaymentMethods()
                                            setLoading(false)
                                            setFormSubmitted(true)
                                        })
                                        .catch(err => {
                                            setLoading(false)
                                            openNotification({
                                                message: 'Payment Method Update Notification', description: 'Payment Method Update Failed'})
                                        })
                                    }
                                }}
                            >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{padding:0}}>
                                    <Form.Item
                                        // initialValue={this.props.option === "edit" ? `**** **** **** ${this.props.paymentDetails?.card?.last4}` : ' '}
                                        label="Card Number" required
                                        name="number"
                                        rules={props.option === "add" && [
                                            {
                                                required: true,
                                                min: 0,
                                                message: 'Please enter a valid card number',
                                            },
                                            {
                                                pattern: new RegExp(/^\d+$/),
                                                message: 'Input must be numeric only!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value.length < 15 && getCreditCardInfo(value)?.name.toLowerCase() === "american express" ) {
                                                        return Promise.reject(new Error('Card number should be 15 digits'));
                                                    }else if(value.length < 16  &&  getCreditCardInfo(value)?.name.toLowerCase() !== "american express"){
                                                        return Promise.reject(new Error('Card number should be 16 digits'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),

                                        ]}
                                    >
                                        <Input
                                            size={"large"}
                                            placeholder="xxxx xxxx xxxx xxxx"
                                            onChange={(event) => {
                                                setNumber(event.target.value)
                                            }}
                                            className="payment-input"
                                            disabled={props.option === "edit"}
                                            maxLength={getCreditCardInfo(number)?.name.toLowerCase() === "american express" ? 15 : 16}
                                        />
                                    </Form.Item>
                                </Col>
                                <Row gutter={[15, 15]} style={{marginTop:20}}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            style={{ margin: 0, padding: 0 }}
                                            label="Expiry" required
                                            name="expiry"
                                            // initialValue={this.props.option === "edit" ? this.props.expiryDate : null}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter a valid date',
                                                },


                                            ]}

                                        >
                                            <InputMask mask="\ 99/99" maskChar=" "
                                                onChange={(e) => {
                                                    if (e.target.value.replace('/', '').trim().length == 0) {
                                                        return
                                                    }

                                                    if (e.target.value.search("_") == -1 && e.target.value != "") {
                                                        setErrorMessage("")
                                                        setExpiryError(false)
                                                        let expiry = e.target.value.split("/")
                                                        let month = new Date().getMonth()
                                                        let year = new Date().getFullYear()
                                                        year = parseInt(year.toString().substr(-2));
                                                        let current_month = parseInt(expiry[0])
                                                        let current_year = parseInt(expiry[1])
                                                        if (current_month > 0 && current_month <= 12 && current_year >= year && current_year != year) {
                                                            setErrorMessage("")
                                                            setExpiryError(false)
                                                            setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                        }
                                                        else if (current_month >= month + 1 && current_month <= 12) {
                                                            if (current_year >= year) {
                                                                setErrorMessage("")
                                                                setExpiryError(false)
                                                                setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                            }
                                                            else {
                                                                setErrorMessage( "Expiry year cannot be in the past")
                                                                setExpiryError(true)
                                                                setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))

                                                            }
                                                        }
                                                        else if (current_year < year) {
                                                            setErrorMessage("Expiry year cannot be in the past")
                                                            setExpiryError(true)
                                                            setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                        }
                                                        else if (current_year == year && current_month < month + 1) {
                                                            setErrorMessage("Expiry month cannot be in the past")
                                                            setExpiryError(true)
                                                            setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                        }
                                                        else {
                                                            setErrorMessage("Expiry month must be between 01 and 12")
                                                            setExpiryError(true)
                                                            setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                        }
                                                    }
                                                    else {
                                                        setErrorMessage("")
                                                        setExpiryError(true)
                                                    }

                                                }}
                                            >{(inputProps) => <Input className="payment-input" size='large' placeholder='mm/yy' />}</InputMask>
                                            {/* <MaskedInput


                                                placeholder="MM/YY"
                                                size="large"
                                                mask="11/11" style={{ padding: '7px', borderRadius: '0.3rem', border: expiryError ? '1px solid red' : '' }}
                                            
                                            /> */}
                                        </Form.Item>
                                        {expiryError && <p style={{ margin: 0, color: 'red', padding: 0 }}>{errorMessage}</p>}
                                    </Col>

                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            // initialValue={this.props.option === "edit" ? "***" : null}
                                            label="CVC" required
                                            name="cvc"
                                            rules={props.option === "add" && [
                                                {
                                                    required: true,
                                                    min: 0,
                                                    message: 'Please enter a valid CVC',
                                                },
                                                {
                                                    pattern: new RegExp(/^\d+$/),
                                                    message: 'Input must be numeric only!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (value.length < 4 && getCreditCardInfo(getFieldValue('number'))?.name.toLowerCase() === "american express" ) {
                                                            return Promise.reject(new Error('CVC should be 4 digits'));
                                                        }else if(value.length < 3  &&  getCreditCardInfo(value)?.name.toLowerCase() !== "american express"){
                                                            return Promise.reject(new Error('CVC should be 3 digits'));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),

                                            ]}
                                        >
                                            <Input
                                                size={"large"}
                                                placeholder= {getCreditCardInfo(number)?.name.toLowerCase() === "american express" ? "xxxx" : "xxx"}
                                                className="payment-input"
                                                disabled={props.option === "edit"}
                                                onChange={(event) => {
                                                    setCvc(event.target.value)
                                                }}
                                                maxLength={getCreditCardInfo(number)?.name.toLowerCase() === "american express" ? 4 : 3}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Button type={"primary"} htmlType="submit" loading={loading} style={{ marginTop: '20px', height:"48px", width:"100%", borderRadius:"5px" }} disabled={canReadPageOnly(props?.user?.role)}>
                                    {props.option === "add" ? "Create Payment Method" : "Update Payment Method"}
                                </Button>
                                </Form>
                            </Col>
                        </Row>                         
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

// class AddEditPaymentModal extends Component {
//     formRef = React.createRef();
//     constructor(props) {
//         super(props);
//         this.state = {
//             cvc: '',
//             expiry: '',
//             number: '',
//             isFormChange: false,
//             loading: false,
//             expiryError: false,
//             errorMessage: ""
//         }
//         this.paymentRepository = new PaymentRepository(this.props.user.token)
//         this.openNotification = (value) => {
//             notification.open({
//                 message: value.message,
//                 description: value.description,
//                 onClick: () => {
//                     console.log('Notification Clicked!');
//                 },
//             });
//         };
//         this.info = (message) => {
//             Modal.info({
//                 title: 'TecMe',
//                 content: (
//                     <div>
//                         <Title></Title>
//                         <Paragraph>{message}</Paragraph>

//                     </div>
//                 ),
//                 onOk() { },
//             });
//         }
//     }

//     render() {
//         console.log(this.props.paymentDetails)
//         console.log(this.props.expiryDate)
//         // if(this.props.expiryDate){
//         //     this.setState({
//         //         expiry:this.props.expiryDate
//         //     })
//         // }
//         // if(this.props.paymentDetails){
//         //     let val = this.props.paymentDetails?.card?.exp_month < 10 ? "0" + this.props.paymentDetails?.card?.exp_month : this.props.paymentDetails?.card?.exp_month;
//         //    this.setState({
//         //     expiry: val + "/" + Array.from(this.props.paymentDetails?.card?.exp_year?.toString(), val => Number(val)).slice(2, 4).join("")
//         //    }) 
//         // }
//         return (
//             <Fragment>
//                 <MetaTags>
//                     <title >{`${this.props.option === "add" ? "Add" : "Edit"} Payment Method` } | TecMe </title>
//                     <meta name="description" content={META_DESCRIPTION} />
//                     <meta name="robots" content="noindex, nofollow" />
//                 </MetaTags>
//                 <Modal 
//                     title={`${this.props.option === "add" ? "Add" : "Edit"} Payment Method` }
//                     width={800}
//                     open={this.props.open} 
//                     onOk={this.props.onOk} 
//                     footer={null}
//                     onCancel={() => {
//                         this.props.onCancel()
//                         this.formRef.current.resetFields();
//                     }}>
//                           <div className="page">
//                         <div>   
//                             <Row gutter={[15, 15]} align="middle" className='payment-method-row' style={{margin:"0 auto"}}>
//                                 <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                                     <div style={{ margin: 0 }}>
//                                         <Cards
//                                             cvc={cvc}
//                                             expiry={this.props.option === "edit" ? this.props.expiryDate : this.state.expiry.replace(/^F0+/i, '')}
//                                             preview={true}
//                                             name=" "
//                                             // issuer={this.props.paymentDetails?.card?.brand || " "}
//                                             number={this.props.option === "edit" ?  `************${this.props.paymentDetails?.card?.last4}` : this.state.number}
//                                         />
//                                     </div>
//                                 </Col>
//                                 <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                                     <Title level={2}>Payment Details</Title>
//                                     <Form
//                                     style={{ width: '100%', margin: '1rem 0' }}
//                                     layout="vertical"
//                                     ref={this.formRef}
//                                     onFinish={(values) => {
//                                         if (this.state.expiryError == true) {
//                                             return
//                                         }
//                                         let expiry = values.expiry.split("/")
//                                         this.setState({ isFormChange: false, loading: true })
//                                         const data = new FormData();
//                                         if(this.props.option === "add") {
//                                             data.append("number", values.number)
//                                             data.append("exp_month", expiry[0])
//                                             data.append("exp_year", expiry[1])
//                                             data.append("cvc", values.cvc)
//                                             this.paymentRepository.addPayment(data)
//                                                 .then(res => {
//                                                     this.setState({ loading: false })
//                                                     this.openNotification({ message: 'Payment Method Notification', description: 'Payment Method Successfully Added' })
//                                                     this.props.history.push(ROUTES.PAYMENT)
//                                                     // this.props.onCancel()
//                                                     // this.formRef.current.resetFields();
//                                                     this.props.fetchPaymentMethods()
//                                                 })
//                                                 .catch(err => {
//                                                     console.log(err?.response?.data?.message)
//                                                     this.setState({ loading: false })
//                                                     this.info(err?.response?.data?.message)
//                                                 })
//                                         }
//                                         if(this.props.option === "edit"){
//                                             data.append("exp_month", String(expiry[0]))
//                                             data.append("exp_year", String(expiry[1]))
//                                             this.paymentRepository.editPayment(this.props.paymentDetails?.id, data)
//                                             .then(res => {
//                                                 this.openNotification({
//                                                     message: 'Payment Method Update Notification',
//                                                     description: 'Payment Method Successfully Updated'
//                                                 })
//                                                 this.props.history.push(ROUTES.PAYMENT)
//                                                 this.props.fetchPaymentMethods()
//                                                 this.setState({ loading: false })
//                                             })
//                                             .catch(err => {
//                                                 this.setState({loading: false})
//                                                 this.openNotification({
//                                                     message: 'Payment Method Update Notification', description: 'Payment Method Update Failed'})
//                                             })
//                                         }

//                                     }}
//                                 >
//                                     <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{padding:0}}>
//                                         <Form.Item
//                                             // initialValue={this.props.option === "edit" ? `**** **** **** ${this.props.paymentDetails?.card?.last4}` : ' '}
//                                             label="Card Number" required
//                                             name="number"
//                                             rules={this.props.option === "add" && [
//                                                 {
//                                                     required: true,
//                                                     min: 0,
//                                                     message: 'Please enter a valid card number',
//                                                 },
//                                                 {
//                                                     min: 16,
//                                                     message: 'Card number should be 16 digits',
//                                                     pattern: new RegExp(/^(?:4[0-9][0-9]{14}?|5[1-5][0-9]{14})$/),
//                                                 }

//                                             ]}
//                                         >
//                                             <Input
//                                                 size={"large"}
//                                                 placeholder="xxxx xxxx xxxx xxxx"
//                                                 onChange={(event) => {
//                                                     this.setState({ number: event.target.value })
//                                                 }}
//                                                 className="payment-input"
//                                                 disabled={this.props.option === "edit"}
//                                             />
//                                         </Form.Item>
//                                     </Col>
//                                     <Row gutter={[15, 15]} style={{marginTop:20}}>
//                                         <Col xs={24} sm={12} md={12} lg={12} xl={12}>
//                                             <Form.Item
//                                                 style={{ margin: 0, padding: 0 }}
//                                                 label="Expiry" required
//                                                 name="expiry"
//                                                 // initialValue={this.props.option === "edit" ? this.props.expiryDate : null}
//                                                 rules={[
//                                                     {
//                                                         required: true,
//                                                         message: 'Please enter a valid date',
//                                                     },


//                                                 ]}

//                                             >
//                                                 <InputMask mask="\ 99/99" maskChar=" "
//                                                     onChange={(e) => {
//                                                         if (e.target.value.replace('/', '').trim().length == 0) {
//                                                             return
//                                                         }

//                                                         if (e.target.value.search("_") == -1 && e.target.value != "") {
//                                                             this.setState({ errorMessage: "", expiryError: false })
//                                                             let expiry = e.target.value.split("/")
//                                                             let month = new Date().getMonth()
//                                                             let year = new Date().getFullYear()
//                                                             year = parseInt(year.toString().substr(-2));
//                                                             let current_month = parseInt(expiry[0])
//                                                             let current_year = parseInt(expiry[1])
//                                                             if (current_month > 0 && current_month <= 12 && current_year >= year && current_year != year) {

//                                                                 this.setState({ errorMessage: "", expiryError: false, expiry: (current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)) })

//                                                             }
//                                                             else if (current_month >= month + 1 && current_month <= 12) {
//                                                                 if (current_year >= year) {
//                                                                     this.setState({ errorMessage: "", expiryError: false, expiry: (current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)) })
//                                                                 }
//                                                                 else {
//                                                                     this.setState({ errorMessage: "Expiry year cannot be in the past", expiryError: true, expiry: (current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)) })

//                                                                 }
//                                                             }
//                                                             else if (current_year < year) {
//                                                                 this.setState({ errorMessage: "Expiry year cannot be in the past", expiryError: true, expiry: (current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)) })
//                                                             }
//                                                             else if (current_year == year && current_month < month + 1) {
//                                                                 this.setState({ errorMessage: "Expiry month cannot be in the past", expiryError: true, expiry: (current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)) })
//                                                             }
//                                                             else {
//                                                                 this.setState({ errorMessage: "Expiry month must be between 01 and 12", expiryError: true, expiry: (current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)) })

//                                                             }
//                                                         }
//                                                         else {
//                                                             this.setState({ errorMessage: "", expiryError: true })
//                                                         }

//                                                     }}
//                                                 >{(inputProps) => <Input className="payment-input" size='large' placeholder='mm/yy' />}</InputMask>
//                                                 {/* <MaskedInput


//                                                     placeholder="MM/YY"
//                                                     size="large"
//                                                     mask="11/11" style={{ padding: '7px', borderRadius: '0.3rem', border: this.state.expiryError ? '1px solid red' : '' }}
                                                
//                                                 /> */}
//                                             </Form.Item>
//                                             {this.state.expiryError && <p style={{ margin: 0, color: 'red', padding: 0 }}>{this.state.errorMessage}</p>}
//                                         </Col>

//                                         <Col xs={24} sm={12} md={12} lg={12} xl={12}>
//                                             <Form.Item
//                                                 // initialValue={this.props.option === "edit" ? "***" : null}
//                                                 label="CVC" required
//                                                 name="cvc"
//                                                 rules={this.props.option === "add" && [
//                                                     {
//                                                         required: true,
//                                                         min: 0,
//                                                         message: 'Please enter a valid CVC',
//                                                     },
//                                                     {
//                                                         min: 3,
//                                                         message: 'CVC should be 3 digits',
//                                                         pattern: new RegExp(/^[0-9+]{3}$/),

//                                                     }

//                                                 ]}
//                                             >
//                                                 <Input
//                                                     size={"large"}
//                                                     placeholder="xxx"
//                                                     className="payment-input"
//                                                     disabled={this.props.option === "edit"}
//                                                     onChange={(event) => {
//                                                         this.setState({ cvc: event.target.value })
//                                                     }}
//                                                 />
//                                             </Form.Item>
//                                         </Col>
//                                     </Row>
//                                     <Button type={"primary"} htmlType="submit" loading={this.state.loading} style={{ marginTop: '20px', height:"48px", width:"100%", borderRadius:"5px" }}>
//                                         {this.props.option === "add" ? "Create Payment Method" : "Update Payment Method"}
//                                     </Button>
//                                     </Form>
//                                 </Col>
//                             </Row>                         
//                         </div>
//                     </div>
//                 </Modal>
//             </Fragment>
//         )
//     }
// }
const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(AddEditPaymentModal);
