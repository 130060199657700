import React from 'react'

const ProjectTag = () => {
  return (
        <div className='project-tag'>
            Project
        </div>
    )
}

export default ProjectTag