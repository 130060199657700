import React, { useEffect, useState } from 'react'
import CompanyLogo from '../assets/images/company-logo.png'
import { SecondaryButton } from './button'
import { MessageOutlined, ShareAltOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../../utils/routes'
import { useSelector } from 'react-redux'
import NewMessageModal from './new-message-modal'
import { notification } from 'antd'
import { getMarketingWebsiteURL, removeSubdomainFromUrl } from '../../../../../../utils/helper'

let MENU_LIST = [
    {
        name: 'Home',
        section: '#home'
    },
    {
        name: 'About us',
        section: '#about-us'
    },
    // {
    //     name: 'Specializations',
    //     section: `#specializations`
    // },
    // {
    //     name: 'Team',
    //     section: `#team`
    // },
    // {
    //     name: 'Testimonials',
    //     section: `#testimonials`
    // },
    // {
    //     name: 'FAQs',
    //     section: `#faqs`
    // }
]

const marketingWebsiteURL = getMarketingWebsiteURL()

const ItcFooter = ({ brandColor, itcContent, activeConversationId, token, groupConversationId, memberItcProfiles }) => {

    const history = useHistory()
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const [messageModalVisible, setMessageModalVisible] = useState(false)
    const [activeNav, setActiveNav] = useState('#home')

    const activeUser = user || tech

    const chatButtonLogic = () => {
        if(!activeUser){
            return <SecondaryButton
                    buttonStyle={{
                        fontSize: '14px',
                        width:156,
                        fontWeight:500,
                        color:brandColor,
                    }}
                    onClick={() => {
                        window.open(`${removeSubdomainFromUrl(itcContent?.Subdomain)}${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${itcContent?.Owner_Contact}`)}`, "_self")	
                    }}
                    >
                        <div className="with-icon">
                            <MessageOutlined style={{color:brandColor}}/>
                            <p>Start a chat</p>
                        </div>   
                </SecondaryButton>
                
        }
        if((tech && memberItcProfiles.map((item) => item.id).includes(itcContent?.id) || (tech?.id === itcContent?.Owner_Contact))){
            return  <SecondaryButton
            buttonStyle={{
                fontSize: '14px',
                width:156,
                fontWeight:500,
                color:brandColor,
            }}
            onClick={() => history.push(`${ROUTES.INBOX}?id=${groupConversationId}`)}
        >
           <div className="with-icon">
                <MessageOutlined style={{color:brandColor}}/>
                <p>Go to group chat</p>
            </div>  
        </SecondaryButton>
        }
        if(activeUser !== null && activeConversationId  && !memberItcProfiles.map((item) => item.id).includes(itcContent?.id)){
            return  <SecondaryButton
                buttonStyle={{
                    fontSize: '14px',
                    width:156,
                    fontWeight:500,
                    color:brandColor,
                }}
                onClick={() => history.push(`${ROUTES.INBOX}?id=${activeConversationId}`)}
            >
               <div className="with-icon">
                    <MessageOutlined style={{color:brandColor}}/>
                    <p>Go to chat</p>
                </div>  
            </SecondaryButton>
        }
        if(activeUser !== null && !activeConversationId && !memberItcProfiles.map((item) => item.id).includes(itcContent?.id)){
            return  <SecondaryButton
                buttonStyle={{
                    fontSize: '14px',
                    width:156,
                    fontWeight:500,
                    color:brandColor,
                }}
                onClick={() => setMessageModalVisible(true)}
            >
                <div className="with-icon">
                    <MessageOutlined style={{color:brandColor}}/>
                    <p>Start a chat</p>
                </div>  
            </SecondaryButton>
        }
    }

     // check for current section based on scroll
     useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const sections = document.querySelectorAll('section')

            sections.forEach(section => {
                const sectionTop = section.offsetTop
                const sectionHeight = section.clientHeight
                if (currentScrollPos >= sectionTop - sectionHeight / 3) {
                    setActiveNav(`#${section.id}`)
                }
            })
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // useEffect(() => {
    //     if(itcContent?.Testimonials?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'Testimonials')
    //     }
    //     if(itcContent?.FAQ?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'FAQs')
    //     }
    //     if(itcContent?.Members?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'Team')
    //     }
    //     if(itcContent?.Categories?.length === 0){
    //         MENU_LIST = MENU_LIST.filter(menu => menu.name !== 'Specializations')
    //     }
    // }, [itcContent])


    return (
        <footer>
            <div className='itc-footer-section'>
                <div className="itc-footer-ctn">
                    { 
                        itcContent?.Brand &&
                        <div className="itc-footer-logo">
                            <img src={itcContent?.Brand} alt="company logo" />
                        </div>
                    }
                    <div className="itc-footer-menu-list-ctn">
                        <ul className='itc-footer-menu-list'>
                            {
                                MENU_LIST.map((menu, index) => {
                                    return (
                                        <li className="itc-footer-menu-item" key={index}>
                                            <a href={menu.section}>{menu.name}</a>
                                        </li>
                                    )
                                })
                            }
                            {
                                itcContent?.Categories?.length > 0 &&
                                <li className="itc-footer-menu-item">
                                    <a href={`#specializations`}>Specializations</a>
                                </li>
                            }
                            {
                                itcContent?.Members?.length > 0 &&
                                <li className="itc-footer-menu-item">
                                    <a href={`#team`}>Team</a>
                                </li>
                            }
                            {
                                (itcContent?.Testimonials?.length > 0 && itcContent?.isProject !== "1") &&
                                <li className="itc-footer-menu-item">
                                    <a href={`#testimonials`}>Testimonials</a>
                                </li>
                            }
                            {
                                itcContent?.FAQ?.length > 0 &&
                                <li className="itc-footer-menu-item">
                                    <a href={`#faqs`}>FAQs</a>
                                </li>
                            }
                        </ul>
                        <div className="itc-footer-cta-ctn">
                            {chatButtonLogic()}
                            {/* <SecondaryButton
                                buttonStyle={{
                                    fontSize: '14px',
                                    width:156,
                                    fontWeight:500
                                }}>
                                    <div className="with-icon">
                                        <ShareAltOutlined/>
                                        <p>Share</p>
                                    </div>   
                            </SecondaryButton> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="itc-copyright-section">
                <div className="itc-copyright-ctn">
                    <p>Copyright © {new Date().getFullYear()} {itcContent?.Company_Name}. All Rights Reserved</p>
                    <p>Powered by TecMe.io</p>
                    <div className='copyright-links'>
                        <a href={`${marketingWebsiteURL}/terms-of-service/`} target={"_blank"}>Terms of service</a>
                        <a href={`${marketingWebsiteURL}/privacy-policy/`} target={"_blank"}>Privacy policy</a>
                    </div>
                </div>
            </div>
            <NewMessageModal
                messageModalVisible={messageModalVisible}
                setMessageModalVisible={setMessageModalVisible}
                itcContent={itcContent}
                activeConversationId={activeConversationId}
                brandColor={brandColor}
            />
        </footer>
    )
}

export default ItcFooter